import React, { useState, useEffect } from 'react'

// external css or images
import ticket1starRed from "../../assets/images/ticket-1star-red.svg";
import ticket1starYellow from "../../assets/images/ticket-1star-red-ajtix.svg";
import ticket1starTfm from "../../assets/images/ticket-1star-red-tfm.svg";

// redux and react hooks
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// child component
import Footer from '../../Layouts/Footer/Footer'
import { HeadingPictureComponent } from '../../Components'

// packages
// Actions & RTK query or mutations
import { useGetLoginMutation, useForgotPasswordMutation } from '../../Services/modules/users';
import { login, setCtAccessEmail, setInfoPartialRegistration, setIsChangePassword } from "../../Store/User";
import { setAlert, setOtpModalStatus, setResetLoginPage } from '../../Store/UI';
import { setIsRegister, updateRegistrationState, updateMobileNumberValidation } from '../../Store/Register';

// Constants
import { loginValidationRules } from '../../Services';
import { actions, label, placeholders, errors, messages, title } from "../../resources/en";
import { routesName } from '../../resources/RoutesName';
import { Config } from '../../Config';
import { NameConstants } from '../../resources/NameConstants';

const Login = () => {

      const [state , setState] = useState({
            [NameConstants.Email] : {value:"",error:false,errorMessage:""},
            [NameConstants.PassWord] : {value:"",error:false,errorMessage:""},
      });
      
      const user                                =     useSelector(state => state.user)
      const resetLoginPage                      =     useSelector(state => state.ui.resetLoginPage)
      const [forgotPassword,setForgotPassword]  =     useState(false);
      const [screenWidth, setScreenWidth]       =     useState(window.innerWidth);
      const dispatch                            =     useDispatch();
      const navigate                            =     useNavigate()
      const location                            =     useLocation()

      const [getLogin, { data, isSuccess, isLoading, isFetching, error }] = useGetLoginMutation();
      const [forgotPasswordAPI, { data : fpData, isSuccess : fpIsSuccess, isLoading : fpIsLoading, isFetching : fpIsFetching, error :fpError }] = useForgotPasswordMutation();

      // check the windows width and change windows width on resize 
      useEffect(()=>{
            const handleResize = () => setScreenWidth(window.innerWidth);
            window.addEventListener('resize', handleResize);

            const searchParams = new URLSearchParams(location.search);
            const action = searchParams.get('action')

            if(action === "password") {
                  setForgotPassword(true) 
            }
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      useEffect(()=>{
            if(resetLoginPage){
                  setForgotPassword(false)
                  dispatch(setResetLoginPage({resetLoginPage : false}))
            }
      },[resetLoginPage, dispatch])

      useEffect(()=>{

            if(isSuccess){
                  if(data.status === "1"){

                        if(Object.values(data.data.userInfo.registration_step).indexOf(false) > -1){

                              const registration_step = data.data.userInfo.registration_step;
                              let ct_otp_modal_status
                              if(!registration_step.otp_verify_email){
                                    ct_otp_modal_status = "otpEmail"
                              } else if(!registration_step.otp_verify){
                                    ct_otp_modal_status = "otp"
                              } else if(!registration_step.otp_verify && !registration_step.otp_verify_email){
                                    ct_otp_modal_status = "otpEmail"
                              }

                              dispatch(setInfoPartialRegistration({
                                    auth : false,
                                    ct_access_token : data.data.token,
                                    ct_refresh_token : data.data.refresh_token,
                                    ct_register_token : "",
                                    ct_access_name : data.data.userInfo.fname +" "+ data.data.userInfo.lname,
                                    ct_access_email : data.data.userInfo.email,
                                    ct_access_mobilenumber : data.data.userInfo.mobilenumber,
                                    ct_is_verified : data.data.userInfo.is_verified,
                                    ct_firebase_key : data.data.userInfo.firebase_key,
                                    ct_reactive : data.data.userInfo.reactive,
                                    ct_is_top_member : data.data.userInfo.ct_is_top_member,
                                    ct_wallet_balance : parseFloat(data.data.userInfo.wallet_balance) || 0
                              }))
                              dispatch(setIsChangePassword({ct_is_change_password : data.data.userInfo.is_change_password || false}))
                              
                              // dispatch(changeToken({ct_access_token : data.data.token}))
                              dispatch(updateRegistrationState({ct_otp_modal_status : ct_otp_modal_status,step : "step2",registration_step : registration_step}))
                              dispatch(updateMobileNumberValidation({mobile_number_validation : data.data.userInfo.mobile_number_validation || null}))
                              navigate(routesName.Register)

                        } else {
                              dispatch(setIsChangePassword({ct_is_change_password : data.data.userInfo.is_change_password || false}))
                              dispatch(login(data.data));
                              // if(user.ct_access_needs) {
                              //       console.log("here acces needs")
                              //       // navigate(routesName.AccessNeeds);
                              //       // dispatch(setAccessNeeds({ct_access_needs : false}))
                              // }else 
                              if(data.data.userInfo.is_change_password){
                                    navigate(routesName.MyAccount)
                                    return
                              }
                              if(user.ct_redirect_url) {
                                    navigate(user.ct_redirect_url)
                                    // dispatch(setCTRedirectUrl({ct_redirect_url : ""}))
                              }
                              else {
                                    navigate(routesName.CurrentListing);
                              }
                        }
                        // dispatch(setWalletBalance({ct_wallet_balance:walletBalance}))
                        dispatch(setIsRegister({isRegister : false}))
                  } else if(data.status === "0"){
                        identifyErrorFromAPI(data.message)
                  }
            }     

            if(error){
                  
                  if(!error.error) {
                        identifyErrorFromAPI(error.data.message || error.data.error)
                  } else {
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message: error.error || error.data.error || errors.NotFound404}))
                  }
            }


      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data,isSuccess,isLoading,isFetching,error,dispatch ])

      useEffect(()=>{

            if(fpIsSuccess){
                  if(fpData.status === "1"){
                        // menuOrLoginHandler()
                        dispatch(setOtpModalStatus({status : "otpForgotPassword"}))
                        dispatch(setCtAccessEmail({ct_access_email : state[NameConstants.Email].value}))
                        // dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message:fpData.message || errors.NotFound404}))
                  } else if(fpData.status === "0"){
                        identifyErrorFromAPI(fpData.message)
                  }
            }     
           
            if(fpError){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:fpError.error || fpError.data.error || fpError.data.message || errors.NotFound404}))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[fpData,fpIsSuccess,fpIsLoading,fpIsFetching,fpError,dispatch ])

      const identifyErrorFromAPI = (message)=>{

            if(message.includes('email')){
                  setState(prevState=>{
                        return {
                              ...prevState,
                              [NameConstants.Email] : {...prevState.email,error:true,errorMessage:message}
                        }
                  })

            } else if(message.includes('password')) {
                  setState(prevState=>{
                        return {
                              ...prevState,
                              [NameConstants.PassWord] : {...prevState.password,error:true,errorMessage:message}
                        }
                  })
            } else {
                  setState(prevState=>{
                        return {
                              ...prevState,
                              [NameConstants.Email] : {...prevState.email,error:true,errorMessage:message}
                        }
                  })
            }
      }

      const handleChange = (e) => {

            const {name , value} = e.target 

            setState(prevState => ({
                        ...prevState,
                        [name] : {...prevState[name],value:value}
                  })
            )
      }

      const onfocusHandler = ()=>{

            setState(prevState=>{

                  return {
                        ...prevState,
                        [NameConstants.Email]:{...prevState.email,error:false,errorMessage:""},
                        [NameConstants.PassWord]:{...prevState.password,error:false,errorMessage:""}
                  }
            })
      }

      const forgotPasswordHandler = (event)=>{
            event.preventDefault();
            onfocusHandler();
            setForgotPassword(!forgotPassword);

      }

      const handleSubmitClick = (e) => 
      {
            e.preventDefault();
           
            let payload = null;

            const errorStore = [];
            const copy = {
            }

            for(let key in state)
            {
               
                  if(forgotPassword && key === NameConstants.PassWord){
                        continue;
                  }
                  const errorCheck = loginValidationRules(state[key].value,key);
                  if(!errorCheck.isValid)
                  {
                        errorStore.push(key);
                  }       
                  copy[key] = {...state[key],error:!errorCheck.isValid,errorMessage:errorCheck.validationMessage}
                        
            }

            if(forgotPassword) {
                  payload={
                        "email":state.email.value
                  }
            } else {
                  payload={
                        "email":state.email.value,
                        "password":state.password.value,
                  }
            }

            if(errorStore.length > 0){
                  setState({...copy})
            } 
            else {

                  if(forgotPassword){
                        forgotPasswordAPI(payload)
                  } else {
                        getLogin(payload);
                  }
            }
                
      }

      return (
            <div className={["scroll-animations position-relative"].join(" ")} data-scroll-container>
                  <main>
                  <section data-scroll-section className={["section__register section__login py-0", forgotPassword && "section__change-password"].join(" ")}>
                  {/* <!--
                                    NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                              --> */}
                              <div className="container-fluid container__block">
                                    <div className="row">
                                          <div className="col-md-12">
                                                <div className="content content__register mx-auto">
                                                      <div className="content__header content__header--template border-bottom text-center overflow-hidden">
                                                            <div className="big-heading">
                                                            <h1 className="h2 mb-0"> {!forgotPassword ? "Login" : title.changePassword }</h1>
                                                            <HeadingPictureComponent 
                                                                  dataScroll=""
                                                                  src="ticket-register-big-heading1-orange-sm.svg"
                                                                  srcSet="ticket-register-big-heading1-orange.svg" className="ticket-orange" />

                                                                  <HeadingPictureComponent 
                                                                  dataScroll=""
                                                                  src="ticket-register-big-heading1-aquamarine-sm.svg"
                                                                  srcSet="ticket-register-big-heading1-aquamarine.svg" className="ticket-aquamarine" />

                                                                  <HeadingPictureComponent 
                                                                  dataScroll=""
                                                                  src="ticket-register-big-heading1-yellow-sm.svg"
                                                                  srcSet="ticket-register-big-heading1-yellow.svg" className="ticket-yellow" />
                                                                 
                                                            </div>
                                                      </div>

                                                      {/* <!-- .content__header --> */}
                                                      <div className="content__body p-0 d-flex flex-column flex-lg-row">
                                                            <div className="col__left border-right-lg d-none d-lg-block">
                                                                  <div className="content">
                                                                        <div className="content__body">
                                                                              <div className="wrapper d-flex flex-column flex-md-row flex-lg-column">
                                                                                    <div className="wrapper">
                                                                                          <h3 className="heading">Perks of membership</h3>
                                                                                          <ul className="list list-square-style">
                                                                                                <li>
                                                                                                      To have access to our list of shows on offer
                                                                                                </li>
                                                                                                <li>
                                                                                                      To save money on shows and events
                                                                                                </li>
                                                                                                <li>
                                                                                                      To find discounts and offers on West End shows
                                                                                                </li>
                                                                                                <li>
                                                                                                      To receive mail alerts on new shows
                                                                                                </li>
                                                                                                <li>
                                                                                                      <span className="red fw-bold">
                                                                                                            Because it’s free!
                                                                                                      </span>
                                                                                                </li>
                                                                                          </ul>
                                                                                    </div>
                                                                                    <div className="feature flex-shrink-md-0">
                                                                                          <img className="ticket-1-star" src={user.domain === Config.DOMAIN_CONSTANT_AJTIX ? ticket1starYellow : ( user.domain === Config.DOMAIN_CONSTANT_TFM ? ticket1starTfm:ticket1starRed)} alt=""/>
                                                                                    </div>
                                                                              </div>
                                                                        </div>
                                                                        {/* <!-- /.content__body --> */}
                                                                  </div>
                                                                  {/* <!-- /.content --> */}
                                                            </div>
                                                            {/* <!-- /.col__left --> */}

                                                            <div className="col__right">
                                                                  <div className="content">
                                                                        <div className="content__body">
                                                                              <form>
                                                                                    {/* <!-- /.row --> */}
                                                                                    <div className="row row__form-fields">
                                                                                          <div className="col-md-12 col-lg-12 col-xl-6">
                                                                                                <div className="form-group">
                                                                                                      <label htmlFor="registerInputEmail" className="form-label">
                                                                                                      {(forgotPassword && screenWidth < 1200) ? "Enter email to change password" : label.email}
                                                                                                      </label>
                                                                                                      <input type="email" className="form-control" id="registerInputEmail" name={NameConstants.Email} required value={state.email.value} onChange={handleChange} onFocus={onfocusHandler} placeholder={placeholders.email}/>
                                                                                                      {state.email.error && <label className="form-label form-control fw-normal form-label-error" dangerouslySetInnerHTML={{__html: state.email.errorMessage}}></label>}
                                                                                                </div>
                                                                                          </div>
                                                                                    </div>
                                                                                    {/* <!-- /.row --> */}
                                                                                    {!forgotPassword &&
                                                                                    <div className="row row__form-fields">
                                                                                          <div className="col-md-12 col-lg-12 col-xl-6">
                                                                                                <div className="form-group">
                                                                                                      <label htmlFor="registerInputPassword" className="form-label">
                                                                                                            {label.password}
                                                                                                      </label>
                                                                                                      <input type="password" className="form-control" id="registerInputPassword" name={NameConstants.PassWord} required value={state.password.value} placeholder={placeholders.password} onChange={handleChange} onFocus={onfocusHandler}/>
                                                                                                      {state.password.error && <label className="form-label fw-normal form-label-error" dangerouslySetInnerHTML={{__html: state.password.errorMessage}}></label>}
                                                                                                </div>
                                                                                          </div>
                                                                                          
                                                                                    </div>}
                                                                                    {/* <!-- /.row --> */}

                                                                                    <p className="fw-normal mb-2 custom-control-label">
                                                                                          Not a member? <Link to={routesName.Register}>Register here.</Link>
                                                                                    </p>

                                                                                    <div className="row row__cta">
                                                                                          <div className="col-md-12">
                                                                                                <div className="form-group form-group__cta">
                                                                                                      <button type="button" className="btn btn-xl-lg btn-primary" onClick={handleSubmitClick}>
                                                                                                            {!forgotPassword ? actions.submit : actions.sendLink}
                                                                                                      </button>
                                                                                                </div>
                                                                                                <p className="fw-normal mb-2">
                                                                                                      {!forgotPassword ? "Forgot the password? " : "Back to login? " }
                                                                                                      <span  className="forgot-password-link" onClick={forgotPasswordHandler} style={{ cursor : "pointer"}} >Click here</span>
                                                                                                </p>
                                                                                          </div>
                                                                                    </div>
                                                                              </form>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.col__right --> */}
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                {/* <!-- /.content.content__register --> */}
                                          </div>
                                          {/* <!-- /.col-md-12 --> */}
                                    </div>
                                    {/* <!-- /.row --> */}
                              </div>
                              {/* <!-- /.container-fluid  --> */}
                        </section>
                        {/* <!-- /.section__register --> */}
                  </main>

                  <Footer />
            </div>
      )
}

export default Login
