export const routesName = {
      Home:"/",
      CurrentListing:"/current-listings",
      MyFavourites:"/my-favourites",
      MyBookings:"/my-bookings",
      EventDetails:"/event-details/:eventId",
      Event:"/event/:eventId",
      MyAccountHome:"/my-account-home",
      MyAccount:"/my-account",
     
      MyWallet:"/my-wallet",
      MyWalletTopUp:"/my-wallet/top-up",
      ReferFriend:"/refer-friend",
      Login:"/login",
      Register:"/register",
      join:"/join",
      joinAlt:"/join-alt",
      RegisterWithVoucher:"/register/gift_vouchers/:voucher",
      RegisterType:"/register/:type",
      OldRegisterVerification:"/verification/:code",
      MembershipGuidlines:"/membership-guidelines",
      LeaveFeedback:"/leave-feedback/:id",
      LeaveFeedbackAnother:"/leavefeedback/:id",
      ChangePassword:"/change_password/:token",
      NoWorkingPage:"/no-working-page",
      UptakePageV2:"/uptake_request/:id",
      UptakePageAllEvents:"/uptake_request_by_venue_all_events/:id",
      UptakePageVenues:"/uptake_request_by_venue/:id",
      AboutUs:"/about-us",
      NewLookPage:"/newlook",
      Faq:"/faq",
      Terms:"/terms",
      FlexiTerms:"/flexi-terms",
      Privacy:"/privacy",
      PrivacyPolicy:"/privacy-policy",
      ContactUs:'/contact-us',
      ResetPassword : "/reset-password",
      ReactiveForm : "/reactive-account-form",
      ReactiveAccount : "/reactive_account/:status",
      Referral:"/invite/:ReferralCode",
      AccessNeeds:"/access_needs",
      FeedbackSurvey:"/survey",
      MoreInfo:"/more-info",
      InstantLogout:"/temporary-user-blocked",
      UnderRepresentedCommunity:"/underrepresented_communities",
      GiftVouchers:"/gift-vouchers",
      LandingPage:"/landing-page",
      WebHomePage:"/web-home-page",
      GetFeedbackReport: "/get_feedback_report/:feedbackId",
      
      // 3D secure payment response
      SuccessBooking:"/event-details/:eventId/payment-success", 
      FailureBooking:"/event-details/:eventId/payment-failure",
      SuccessAddBalance:"/my-wallet/payment-success",
      FailureAddBalance:"/my-wallet/payment-failure",
      SuccessAddVoucher: "/gift-vouchers/payment-success",
      FailureAddVoucher: "/gift-vouchers/payment-failure",
      FailureReactiveAccount : "/reactive_account_response/payment-failure",
      SuccessReactiveAccount : "/reactive_account_response/payment-success",
      SuccessCardStore:"/my-account/payment-success",
      FailureCardStore:"/my-account/payment-failure",

      // Web View Routes
      VoucherForGift : "/vouchers-for-gift/:token",
      SuccessVoucherForGift : "/vouchers-for-gift/:token/payment-success",
      FailureVoucherForGift : "/vouchers-for-gift/:token/payment-failure",

      SavedCard : "/save-card/:token",
      SuccessCardStoreWebView : "/save-card/:token/payment-success",
      FailureCardStoreWebView : "/save-card/:token/payment-failure",

      WalletTopUp : "/wallet-topup/:token",
      SuccessAddBalanceWebView : "/wallet-topup/:token/payment-success",
      FailureAddBalanceWebview : "/wallet-topup/:token/payment-failure",

      BookingModule : "/booking-module/:token",
      SuccessBookingWebView : "/booking-module/:token/payment-success",
      FailureBookingWebView : "/booking-module/:token/payment-failure",

      WebMembershipGuidlines : "/membership_guidelines",

      //Ajtix
      AjtixHome : "/ajtix-home"
}
      
