import React, { useEffect, useState } from 'react';

// external css
import "./WebHomePage.css"
// import LandingPageBackgroundImage from "../../assets/images/landing-page-background.png"
// redux and react hooks
import { useDispatch } from 'react-redux';
// child component
import Footer from "../../Layouts/Footer/Footer";
// packages
// Actions & RTK query or mutations
import { useLazyAppHomeConfigQuery } from '../../Services/modules/Home';
import { updateLocoScroll } from '../../Store/UI';
// Constants

// content__header--template
import ticketCurrentListingsBigHeading1Blue from "./../../assets/images/tickets/ticket-current-listings-big-heading1-blue.svg";
import ticketCurrentListingsBigHeading1BlueSm from "./../../assets/images/tickets/ticket-current-listings-big-heading1-blue-sm.svg";

import ticketReferFriendBigHeading1Aquamarine from "./../../assets/images/tickets/ticket-refer-friend-big-heading1-aquamarine.svg"
import ticketReferFriendBigHeading1AquamarineSm from "./../../assets/images/tickets/ticket-refer-friend-big-heading1-aquamarine-sm.svg"

import ticketHomeBigHeading1Red from "./../../assets/images/tickets/ticket-home-big-heading1-red.svg"

import ticketHomeBigHeading3Yellow from "./../../assets/images/tickets/ticket-home-big-heading3-yellow.svg"


import SectionHeading from './SectionHeading';
import HomeCarousel from './HomeCarousel';
import HomeCarouselExternal from './HomeCarouselExternal';
import SubTypeInfo from './SubTypeInfo';
import SubTypeInfoEcology from './SubTypeEcology';

let timeoutId = null;


const WebHomePage = () => {

      const [device,setDevice]            =     useState('');
      const [homeData,setHomedata]        =     useState([]);
      const dispatch                      =     useDispatch()

      let width                           =     window.innerWidth

      const [loadHomeConfiguration, { data  }] = useLazyAppHomeConfigQuery();

      useEffect(()=>{
            loadHomeConfiguration()
            if(width > 767){
                  setDevice('desktop')
            } else {
                  setDevice('mobile')
            }
            console.log(device)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[loadHomeConfiguration])

      useEffect(()=>{
            if(data) {
                  setHomedata(data?.data?.Sections)
            }
      },[data])

      // const getDateForEcologiLogo = ()=>{
      //       const date = Date.now()
      //       return `ecology_logo_red.png?v=${date}`
      // }


      const afterLoadHandler = ()=>{
            if (timeoutId) {
                  clearTimeout(timeoutId);
            }
            // Schedule the update to occur after a certain delay
            timeoutId = setTimeout(updateLocomotiveScroll, 700);
      }

      const updateLocomotiveScroll = ()=> {
            dispatch(updateLocoScroll({locoScroll:{change:true,type:'update'}}))
      }



      const renderHomeItem = (data, index)=> {
            
            switch (data.SectionType) {
                  case 'Carousel':
                        return (
                              <div className="carousel-section" key={"HomeSection_" + index.toString()}>
                                    <SectionHeading data={data} />
                                    {data.SubType === "External" ?
                                          <HomeCarouselExternal data={data} index={index} key={`${data.SectionType} + ${index.toString()}`} afterLoad={afterLoadHandler}/> : 
                                          <HomeCarousel data={data} index={index} key={`${data.SectionType} + ${index.toString()}`}  afterLoad={afterLoadHandler} />
                                    }
                              </div>
                        )
                  case 'HighligtedShows':
                        return (
                              <div className="carousel-section highligtedShows-section" key={"HomeSection_" + index.toString()}>
                                    <SectionHeading data={data} />
                                    <HomeCarousel data={data} index={index} key={`${data.SectionType} + ${index.toString()}`} isHighlighted={true}  afterLoad={afterLoadHandler} />
                              </div>
                        )
                  case 'Flyer' :
                        if (data.SubType === "Info") {
                              return (
                                    <SubTypeInfo data={data} key={"FlyerSubTypeInfo"} />
                              )
                        } else if(data.SubType === "Wallet") {
                              return (
                                    <SubTypeInfo data={data} key={"FlyerSubTypeWallet"} />
                              )
                        } else if(data.SubType === "Refer") {
                              return (
                                    <SubTypeInfo data={data} key={"FlyerSubTypeRefer"}/>
                              )
                        } else if(data.SubType === "Ecology") {
                              return (
                                    <SubTypeInfoEcology data={data} key={"FlyerSubTypeEcology"} />
                              )
                        }
                        return null;
                  default:
                        return null;
            }
      }     


      return (
            
            <React.Fragment>
                  {/* <!-- REQUIRED wrapper div for parallax --> */}
                  <div className="scroll-animations position-relative" data-scroll-container>
                        
                        <main className="main__home main__landing web-home-page-container">

                              <section data-scroll-section className="section__web-hero border-bottom ">
            
                                    <div className="container-fluid">
                                        <div className="row" >
                                                <div className="col-md-12">
                                                      <div className="content content__web-home-page">
                                                            <div className="content__header content__header--template text-center overflow-hidden" >
                                                                  <div className="big-heading">
                                                                        <h1 className="mb-0">What's on</h1>
                                                                        <picture>
                                                                              <source media="(min-width: 992px)" srcSet={ticketCurrentListingsBigHeading1Blue}/>
                                                                              <img className="ticket ticket-blue" src={ticketCurrentListingsBigHeading1BlueSm} alt=""/>
                                                                        </picture>

                                                                        <picture>
                                                                              <source media="(min-width: 992px)" srcSet={ticketReferFriendBigHeading1Aquamarine}/>
                                                                              <img className="ticket ticket-aquamarine" src={ticketReferFriendBigHeading1AquamarineSm} alt=""/>
                                                                        </picture>

                                                                        <picture>
                                                                              <source media="(min-width: 992px)" srcSet={ticketHomeBigHeading1Red}/>
                                                                              <img className="ticket ticket-red" src={ticketHomeBigHeading1Red} alt=""/>
                                                                        </picture>

                                                                        <picture>
                                                                              <source media="(min-width: 992px)" srcSet={ticketHomeBigHeading3Yellow}/>
                                                                              <img className="ticket ticket-yellow" src={ticketHomeBigHeading3Yellow} alt=""/>
                                                                        </picture>

                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                        </div>
                                    </div>
                                    {/* <!-- /.container-fluid  --> */}
                              </section>
                              <section  data-scroll-section className='dynamics-sections'>
                                    <div className="container-fluid">
                                          {homeData && homeData.map((data, index)=>{
                                                return renderHomeItem(data,index)
                                          })}
                                    </div>
                              </section>
                         
                        </main>

                        <Footer/>
                  </div>
                  {/* <!-- /div[data-scroll-container] --> */}
            </React.Fragment>
      )
}

export default WebHomePage;
