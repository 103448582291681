import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';


// external css
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "./assets/css/stylesheet.css";
import './App.css';
import "./AjtixApp.css"
import "./TfmApp.css"
import "./DarkMode.css"

// redux and react hooks
import {  Routes, Route, useLocation, Navigate, useNavigate } from 'react-router-dom';
import {  useSelector, useDispatch } from "react-redux";

// child component
import MainLayout from './Layouts/MainLayout';
import { 
      Home, NoWorkingPage, CurrentListings, AjtixHome, CookiesConsentPage
} from "./Pages";
import AuthenticatedRoutes from "./resources/AuthenticatedRoutes";
import UnAuthenticatedRoutes from "./resources/UnAuthenticatedRoutes";
import WebViewRoutes from './resources/WebViewRoutes';
import Modal from "./Ui/Modal/Modal";
import UpdateNameModal from './Ui/Modal/UpdateNameModal';
import ErrorBoundary from './ErrorBoundary';
import OtpMyAccountModal from './Ui/Modal/OtpMyAccountModal';


// packages
import LocomotiveScroll from 'locomotive-scroll';
import imagesLoaded from 'imagesloaded';
import { browserName, browserVersion } from "react-device-detect";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Transition } from 'react-transition-group';
import ReactPixel from 'react-facebook-pixel';

// Actions & RTK query or mutations
import { useGetUserWalletBalanceQuery } from './Services/modules/Wallet';
import { useOtpVerificationMutation } from './Services/modules/Otp';
import { useLazyEventStopWaitingListEmailQuery } from './Services/modules/WaitingListEmails';
import { setWalletBalance, setCTRedirectUrl, setTomMember, setCtSurveyRedirect } from './Store/User';
import { setBookingHandler } from './Store/EventDetail';
import { reset as resetCurrentListing } from './Store/CurrentListing';
import { setAlert, confirmMessage, setBrowserDetect, updateLocoScroll, 
      setScrollToTarget, setOtpModalStatus, setResetPasswordCode, setSpinner, setRedirectToTemPausedPage, setRefreshListing } from "./Store/UI";
import { logout, setCtWalletTimeStamp, setShowUpdateTimestamp, setRegistrationType, setGiftVoucher, setCtUserInputFields, setDomain, reset as resetUserSlice } from './Store/User';

// Constants
import { ClassChangeFunction, WhiteThemeAboutUs } from "./Shared";
import { routesName } from './resources/RoutesName';
import { messages, errors } from './resources/en';
import { reset } from './Store/BookingModule';
import { api } from './Services/Api';
import { Config } from './Config';

import StartFirebase from "./Config/firebase";
import { ref, onValue } from "firebase/database";

const db = StartFirebase();
let locoScroll;
let isScrolling;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)


function App() {
      
      const [scrollTo, setScrollTo]             =     useState(false)
      const [webView, setWebView]               =     useState(false)
      const user                                =     useSelector(state => state.user);
      const ui                                  =     useSelector(state => state.ui);
      const eventDetail                         =     useSelector(state => state.eventDetail);
      const location                            =     useLocation();
      const dispatch                            =     useDispatch();
      const navigate                            =     useNavigate();
      
      const nodeRef                             =     useRef(null);
      
      const { data, isSuccess, error, isLoading, refetch  } = useGetUserWalletBalanceQuery();
      const [otpVerification,{ data : otpData , isSuccess : otpIsSuccess , isLoading :otpIsLoading , isFetching : otpIsFetching , error : otpError, reset : resetOTPVerification }]  =    useOtpVerificationMutation();

      const [stopWaitingListEmails,{ data : stopData , isSuccess : stopDataIsSuccess , error : stopDataError }] = useLazyEventStopWaitingListEmailQuery()

      // Get Domain and render file according to it
      useLayoutEffect(()=>{
            const currentDomain = window.location.hostname;
            if(currentDomain.includes(Config.AJTIX_URL_CHECK)){
                  dispatch(setDomain({domain :Config.DOMAIN_CONSTANT_AJTIX}))
            } else if(currentDomain.includes(Config.TFM_URL_CHECK)){
                  dispatch(setDomain({domain :Config.DOMAIN_CONSTANT_TFM}))
            }
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])


      // Facebook pixel
      useEffect(()=>{
            if(!user.consent) {
                  dispatch(resetUserSlice())
            }

            if(user?.consent?.marketing) {
                  //  Facebook Pixel configuration
                  let advancedMatching = null; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
                  let options = {
                        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
                        debug: false, // enable logs
                  };
                  const FacebookPixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID
                  ReactPixel.init(FacebookPixelId, advancedMatching, options);
            }

            if(user?.consent?.advertising) {
                  loadGoogleAnalytics()
            }
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[user?.consent])

      // Function to load Google Analytix
      const loadGoogleAnalytics = () => {
            const GoogleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
            const script = document.createElement('script');
            script.src = `https://www.googletagmanager.com/gtag/js?id=${GoogleAnalyticsId}`;
            script.async = true;
            document.head.appendChild(script);
            
          
            script.onload = () => {
                  window.dataLayer = window.dataLayer || [];
                  function gtag() {
                        window.dataLayer.push(arguments);
                  }
                  gtag('js', new Date());
                  gtag('config', `${GoogleAnalyticsId}`, {
                        anonymize_ip: true, // Ensure IP anonymization
                  });
            };
      };

      useEffect(()=>{
            if(location.pathname.includes("/vouchers-for-gift") || location.pathname.includes("/save-card")
                  || location.pathname.includes("/wallet-topup") || location.pathname.includes("/booking-module")
                  || location.pathname.includes(routesName.WebMembershipGuidlines)
            ) {
                  setWebView(true)
            } else {
                  setWebView(false)
            }
            if(user?.consent?.marketing){
                  ReactPixel.pageView(); // For tracking page view
            }
            if(user.auth){

                  if(user.ct_is_change_password && ((!location.pathname.includes(routesName.MyAccount)  || location.pathname.includes(routesName.MyAccountHome)) && !location.pathname.includes(routesName.ContactUs))) {
                        navigate(routesName.MyAccount)
                  }
            } 
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[location.pathname, user?.consent])

      // Refetch Wallet Balance API After user got loged in
      useEffect(()=>{
            if(user.auth) {
                  refetch()
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[user.auth])

      // Handler Firebase instant logout feture
      useEffect(()=>{
            
            if(user.ct_firebase_key) {
                 
                  const dbRefListing = ref(db,'/asyncPushes/lastUpdates')
                  onValue(dbRefListing, (snapshot) => {
                        const data = snapshot.val();

                        // console.log(data)

                        let todoItems = {...data};
                        console.log("todo items for last update",todoItems)
                        if(todoItems.showUpdates) {
                              if(todoItems.showUpdates > user.ct_show_update_timestamp) {
                                    dispatch(setShowUpdateTimestamp({ct_show_update_timestamp : todoItems.showUpdates}))
                                    dispatch(setRefreshListing({refreshListing : true}))
                              }
                        }
                        
                  });

                  const dbRef = ref(db,'/contact/'+user.ct_firebase_key)
                  onValue(dbRef, (snapshot) => {
                        const data = snapshot.val();
                        let todoItems = {...data};
                        console.log("todo item register inside", JSON.stringify(todoItems))
                        if(todoItems.wallet_update){
                              if(todoItems.wallet_update !== user.ct_wallet_timestamp){
                                    dispatch(setCtWalletTimeStamp({ct_wallet_timestamp : todoItems.wallet_update}))
                                    refetch();
                              }
                        }

                        // Update Tom Member status as per status changed in firebase
                        if(todoItems.is_top_member){
                              dispatch(setTomMember({ct_is_top_member :  1}))
                        } 
                        
                        // if we got status 4 logout user - instructed by sagar
                        if (todoItems.block_status === 1 || todoItems.block_status === 2 || todoItems.block_status === 3 || todoItems.block_status === 4)
                        {          
                              // navigate(routesName.InstantLogout)
                              dispatch(api.util.resetApiState());
                              console.log("logout using firebas status")
                              dispatch(logout())
                              if(todoItems.block_status !== 4){
                                    dispatch(setRedirectToTemPausedPage({redirectToTemPausedPage : true}))
                                    dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:"A pause has been applied to your account. &nbsp;We have sent you an email with further details."}))
                              }
                              // localStorage.setItem("pauseAccount",true)
                        }   
                  });
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[user.ct_firebase_key, dispatch, navigate])

      // Redirect User to Temporary Paused page after Blocked from backedn
      useEffect(()=>{
            if(ui.redirectToTemPausedPage){
                  navigate(routesName.InstantLogout)
                  dispatch(setRedirectToTemPausedPage({redirectToTemPausedPage : false}))
            }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[ui.redirectToTemPausedPage])
      
      useEffect(()=>{
            if(isSuccess) {
                  if(data.status === "1"){
                        dispatch(setWalletBalance({ct_wallet_balance:parseFloat(data.balance)}));

                        if(data.survey.is_negative_reviews === 1) {
                              if(!user.ct_surevy_redirect){
                                    dispatch(setCtSurveyRedirect({ct_surevy_redirect : 'negative_reviews'}))
                                    navigate(routesName.FeedbackSurvey)
                              }
                        }
                        
                        if(data.survey.user_input_fileds_status === 0){
                              dispatch(setCtUserInputFields({ct_user_input_fields : data.survey.user_input_fileds}))
                              navigate(routesName.MoreInfo)
                        } else if(data.survey.user_input_fileds_status === 1) {
                              dispatch(setCtUserInputFields({ct_user_input_fields : null}))
                        }
                  } 
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data, isSuccess, error, isLoading, dispatch])

      // Verify Email OTP Using OTPVerify
      useEffect(()=>{

            if(location.pathname.includes("app_verify_email") || location.pathname.includes('change_password') || location.pathname.includes('register_verify_email') || location.pathname.includes('change_password_for_old_account')) {
                  const pathArray = location.pathname.split("/");

                  const verification_code = pathArray[2] ? pathArray[2] : "";
                  const email = pathArray[3] ?  pathArray[3] : ""
                  let workflow = "";

                  if(pathArray[1]=== "app_verify_email"){
                        workflow = "change_email"
                  } else if(pathArray[1]=== "change_password") {
                        workflow = "forget_password"
                  } else if(pathArray[1]=== "register_verify_email") {
                        workflow = "registration"
                  } else if(pathArray[1] === "change_password_for_old_account"){
                        workflow = "old_deleted_account_forget_password"
                  }

                  otpVerification({
                        "otp":verification_code,
                        "type":"email",
                        "workflow":workflow,
                        "email":email
                  })

                  navigate('',{ replace : true})
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[location.pathname,otpVerification])

      // Verify Email OTP Using OTPVerify - Handler API response
      useEffect(()=>{

            if(otpIsSuccess){
                  if(otpData.status === "1"){
                        dispatch(setOtpModalStatus({status : ""}))
                        // if(location.pathname.includes("change_password")){
                        let code = otpData.data && otpData.data.code
                        if(code){
                              dispatch(setResetPasswordCode({ct_reset_password_code : otpData.data.code}));
                              navigate(routesName.ResetPassword)
                        } else {
                              dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message:otpData.message || errors.NotFound404}))
                              dispatch(logout());
                              console.log("logout using otpIsSuccess")
                              dispatch(api.util.resetApiState());
                        }
                  } else if(otpData.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:otpData.error || otpData.message || errors.NotFound404}));
                  }
                  
                  if(!location.pathname.includes("change_password")){
                        // navigate(routesName.Home)
                  }

                  resetOTPVerification()
            }
            
            if(otpError){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:otpError.error || otpError.data.error || otpError.data.message || errors.NotFound404}));
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[otpData , otpIsSuccess, otpIsLoading , otpIsFetching , otpError , dispatch, navigate])

      // Stop Waiting List Email Using link - Handler API response
      useEffect(()=>{

            if(stopDataIsSuccess){
                  dispatch(api.util.resetApiState('stopEmailApi')); // Reset a specific API state (replace 'apiSlice' with your API slice name)
                  if(stopData.status === "1"){
                        dispatch(setAlert({model:true,title:messages.modalSuccessTitle,message:stopData.message || errors.NotFound404}))
                  } else if(stopData.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:stopData.error || stopData.message || errors.NotFound404}));
                  }
            }
            
            if(stopDataError){
                  dispatch(api.util.resetApiState('stopEmailApi')); // Reset a specific API state (replace 'apiSlice' with your API slice name)
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:stopDataError.error || stopDataError.data.error || stopDataError.data.message || errors.NotFound404}));
            }
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[stopData , stopDataIsSuccess, stopDataError , dispatch, navigate])

      // Redirect or handler response from 3D secure 
      useEffect(()=>{   

            if(location.pathname.includes(routesName.SuccessReactiveAccount) || location.pathname.includes(routesName.FailureReactiveAccount)){
                  const status = location.pathname.includes("payment-success") ? "success" : "failure"
                  const title = status === "success" ? messages.modalSuccessTitle : messages.modalAlertTitle
                  const message = status === "success" ? messages.SuccessReactiveAccount : messages.FailureReactiveAccount
                  dispatch(setAlert({model:true,title:title,message:message || errors.NotFound404}))
                  navigate('', { replace: true });
            }
            // Remove from waiting list using link
            if(location.pathname.includes("stop_waiting_list_emails")){ 
                  const event_id = location.pathname.split("/")[2]
                  stopWaitingListEmails({
                        event_id : event_id
                  })
                  navigate('', { replace: true });
            }

            // Set Registration type if provided in URL
            if(location.pathname.includes("/register/") && !location.pathname.includes("/register/gift_vouchers/")){
                  const registrationType = location.pathname.split("/")[2] || ""
                  if(registrationType.length > 1) {
                        dispatch(setRegistrationType({ct_registration_type : registrationType}))
                        navigate('', { replace: true });
                  }
            }

            // Set Gift Voucher if provided in URl
            if(location.pathname.includes("/register/gift_vouchers/")){
                  const giftVoucher = location.pathname.split("/")[3] || ""
                  if(giftVoucher.length > 1) {
                        dispatch(setGiftVoucher({ct_gift_voucher : giftVoucher}))
                        // navigate('', { replace: true });
                  }
            }

            // Check that if ct_redirect url is set and routes is diffrent than redirect to appropriate survey page
            if(!location.pathname.includes(routesName.MoreInfo) && user.ct_user_input_fields && user.auth){
                  let redirectPath = ""
                  if(user.ct_user_input_fields){
                        redirectPath = routesName.MoreInfo
                  }
                  navigate(redirectPath);
            }

            if(!user.ct_surevy_redirect && location.pathname.includes(routesName.FeedbackSurvey)){
                  navigate(routesName.CurrentListing, { replace: true });
            }

            // Close Booking Module if Path Change to get rid of error while click on forward and backward page error
            // exclude path 'event-details' and 'event'
            if(eventDetail.isBooking && (!location.pathname.includes("event-details") && location.pathname.includes("event"))){
                  dispatch(reset())
                  dispatch(setBookingHandler({isBooking : false}));
            }

            // Access Needs Check if user were not login set access need and redirect to access need after login
            if(location.pathname.includes(routesName.AccessNeeds) && !user.auth){
                  // dispatch(setAccessNeeds({ct_access_needs : true}))
                  dispatch(setCTRedirectUrl({ct_redirect_url : routesName.AccessNeeds}))
                  navigate('', { replace: true });
            }     

            // If user were not loged and hit event detail page so we store event detail path to redirect user after login
            if((location.pathname.includes('event-details') || (location.pathname.includes('event') && !location.pathname.includes('admin'))) && !user.auth && !location.pathname.includes('uptake_request')){
                  dispatch(setCTRedirectUrl({ct_redirect_url : location.pathname || ""}))
                  navigate('', { replace: true });
            }

            if(location.pathname.includes('my-wallet') && !user.auth){
                  dispatch(setCTRedirectUrl({ct_redirect_url : location.pathname || ""}))
                  navigate('', { replace: true });
            }

            if(location.pathname.includes('gift-vouchers') && !user.auth){
                  dispatch(setCTRedirectUrl({ct_redirect_url : location.pathname || ""}))
                  navigate('', { replace: true });
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[location.pathname])

      useEffect(() => {

            if(document.querySelector('[data-scroll-container]')){

                  locoScroll = new LocomotiveScroll({
                        el: document.querySelector('[data-scroll-container]'),
                        smooth: true,
                        reloadOnContextChange: true,
                        mobile: {
                              smooth: true
                        },
                        tablet: {
                              smooth: true
                        }
                  });

                  if(!location.pathname.includes("current-listings") && !location.pathname.includes("/event-details/")){
                        imagesLoaded(document.querySelector('[data-scroll-container]'), function( instance ) {
                              locoScroll.update();
                        });
                  }
                  
                  // Get limit of scroll position in current listing
                  locoScroll.on('scroll', ({ limit, scroll }) => {

                        // Class change code in about-us and membership guidlines
                        const locoScrollProgress = scroll.y / limit.y * 100
                        ClassChangeFunction(location.pathname,locoScrollProgress)
                      
                  });

                  // Check URL if URL have any state for scroll then update scrollTo State
                  if(location.state){

                        if(location.state.scroll){
                              setScrollTo(true)
                        }
                  }

                  // about us page add white theme to spy scrollbar
                  WhiteThemeAboutUs(location.pathname,locoScroll)
                  
            }

            return () => {
                  if(locoScroll){
                        locoScroll.destroy()	
                  }
            }
           
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [location.pathname,user.domain])

      useEffect(()=>{
            const eventId = localStorage.getItem('eventId')
            if(location.pathname.includes("current-listings") && eventId) {
                  
                  const target = document.getElementById(eventId);
                  if(target) {
                        target.scrollIntoView({ behavior: 'smooth' });
                  } else {
                        setTimeout(() => {
                              dispatch(setSpinner({status : ""}));
                        }, 200)
                  }

                  localStorage.removeItem('eventId')
                  const handleScroll = () => {
                        clearTimeout(isScrolling);
                        isScrolling = setTimeout(() => {
                              dispatch(setSpinner({status : ""}));
                        }, 200); // Adjust the timeout value as needed
              
                        return () => clearTimeout(isScrolling);
                  };
                  window.addEventListener('scroll', handleScroll);
                  return () => window.removeEventListener('scroll', handleScroll);
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[location.pathname])
    
      useEffect(()=>{
            if(location.pathname === "/" && user.auth){

                  if(user.ct_redirect_url){
                        dispatch(setCTRedirectUrl({ct_redirect_url : ""}))
                  }else {
                        navigate(routesName.CurrentListing)
                  }
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[location.pathname, user.auth, navigate])

      // Reset Redux slice current listing while user loged out 
      useEffect(()=>{
            if(!user.auth){
                  dispatch(resetCurrentListing())
            }
      },[user.auth, dispatch])

      // Redirect to wallet section in Terms Page
      useEffect(()=>{
            if(ui.scrollTo) {
                  setScrollTo(true)
                  // locoScroll.scrollTo(target, { offset : -150});
            }

      },[ui.scrollTo])

      // Redirect to section which get From URL
      useEffect(()=>{

            // Redirect to wallet section in Terms Page

            if(ui.scrollTo){
                  // const target = document.getElementById("wallet");
                  const target = document.getElementById(ui.scrollTo);
                  
                  locoScroll.scrollTo(target, { offset : -150});

                  // reset UI scrollTo opetion if needed
                  dispatch(setScrollToTarget({scrollTo : ""}))
            }

            if(location.state && scrollTo){
                  if(location.state.scroll){
                        const target = document.querySelector(`#${location.state.scroll}`);
                        locoScroll.scrollTo(target);
                        setScrollTo(false)
                  }
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[scrollTo])

      useEffect(()=>{

            let locoScrollValue = "";
            if(ui.locoScroll.change && !location.pathname.includes("current-listings") && !location.pathname.includes("/event-details/")){

                  if(ui.locoScroll.type === "update") {
                        if(locoScroll){
                              locoScroll.update();
                        }
                        locoScrollValue = ""
                  } else if(ui.locoScroll.type === "stop") {
                        if(locoScroll){
                              locoScroll.stop();
                        }
                        locoScrollValue = "start"
                  } else if(ui.locoScroll.type === "start"){
                        if(locoScroll){
                              locoScroll.start();
                        }
                        locoScrollValue = "stop"
                  }
                  dispatch(updateLocoScroll({locoScroll:{change:false,type:locoScrollValue}}))
            }

             // Add Inner scroller for Wallet History
            var innerScroller = document.getElementById("inside-js-scroll");
            if(innerScroller){

                  innerScroller.onmouseover = function(event) { locoScroll.stop(); };

                  innerScroller.onmouseout = function(event) { locoScroll.start(); };
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[ui.locoScroll, dispatch])
      
      useEffect(()=>{
            // Browser Name & Browser Version
            if(browserName.includes("Safari") && browserVersion === "13"){
                  dispatch(setBrowserDetect({browserDetect:true}))
            }
            // console.log(locoScroll)
            // locoScroll.on('call', ({ limit, scroll }) => {
                 
            //       console.log("scroll...")
            //   });
      },[dispatch])

      const closeBackdropHandler = ()=> {
            if(ui.alert.type.value === "ticAlert"){ 
                  navigate(routesName.MyBookings)
            }

            if(ui.alert.title.includes("Thank you!") && location.pathname.includes(routesName.FeedbackSurvey)){
                  navigate(routesName.CurrentListing)
            }
            dispatch(setAlert({model:false,message:''}))
      }

      const confirmMessageHandler = ()=>{
            if(ui.alert.type.value === "ticAlert"){
                  dispatch(setAlert({model:false,message:''}))
                  return
            }
            dispatch(confirmMessage({type:{...ui.alert.type,value:"confirm",status:true}}))
      }

      // Redirect to Reactive Form if it's not on Reactive Form Page and loged in and required to Reactive
      if(user.ct_access_token  && user.ct_reactive === "1") {
            if(!location.pathname.includes(routesName.ReactiveForm)){
                  return <Navigate to={routesName.ReactiveForm} />;
            }
      }

      return (

            <React.Fragment>
                  {(user.consent && !user?.consent?.essential && !user?.consent?.advertising && !user?.consent?.marketing  && !webView)&&<CookiesConsentPage /> }
                  <ErrorBoundary fallback={<NoWorkingPage/>}> 
                  {<Modal show={ui.alert.model} type={ui.alert.type} isAjtix={user.domain === Config.DOMAIN_CONSTANT_AJTIX} isTfm={user.domain === Config.DOMAIN_CONSTANT_TFM} closeBackdrop={closeBackdropHandler} confirmMessage={confirmMessageHandler} title={ui.alert.title}>{ui.alert.message}</Modal>}
                  {<UpdateNameModal show={user.ct_name_length_short}  isAjtix={user.domain === Config.DOMAIN_CONSTANT_AJTIX} isTfm={user.domain === Config.DOMAIN_CONSTANT_TFM} />}
                  <Transition in={ui.ct_otp_modal_status === "otp" || ui.ct_otp_modal_status === "otpEmail" || ui.ct_otp_modal_status === "otpForgotPassword"} timeout={500} mountOnEnter unmountOnExit nodeRef={nodeRef}>
                        {status => {
                                    return <OtpMyAccountModal isAjtix={user.domain === Config.DOMAIN_CONSTANT_AJTIX} isTfm={user.domain === Config.DOMAIN_CONSTANT_TFM}  status={status}/> 
                              }
                        }
                  </Transition>

                  <Elements stripe={stripePromise}>
                        <Routes>
                              <Route path="/" element={<MainLayout/>}>
                                    {user.auth && AuthenticatedRoutes}
                                    {UnAuthenticatedRoutes}
                                    {user.auth ? <>
                                    <Route path="*" element={<CurrentListings/>}/>
                                    <Route index element={<CurrentListings/>}/>
                                    </> : <>
                                    <Route path="*" element={user.domain === Config.DOMAIN_CONSTANT_AJTIX ? <AjtixHome /> : <Home/>}/>
                                    <Route index element={user.domain === Config.DOMAIN_CONSTANT_AJTIX ? <AjtixHome /> : <Home/>}/>
                                    {/* <Route index element={<AjtixHome /> }/> */}
                                    {/* <Route path="*" element={<AjtixHome />}/> */}
                                    </>}
                              </Route>
                              {/* <Route path="*" element={<MainLayout/>}>
                                    <Route path="*" element={<Home/>}/>
                              </Route> */}
                              <Route>
                                    {WebViewRoutes}
                              </Route>
                        </Routes>
                  </Elements>
                  </ErrorBoundary>
            </React.Fragment>
      );

}

export default App;
