import React, { useState, useEffect } from 'react'

// external css
// redux and react hooks
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// child component
// packages
// Actions & RTK query or mutations
import { setResetLoginPage } from '../../../Store/UI';
// Constants
import { title } from '../../../resources/en';
import { routesName } from '../../../resources/RoutesName';

const UnAuthenticatedMenus = (props) => {

      const [screenWidth, setScreenWidth] = useState(window.innerWidth);
      const navigate = useNavigate()
      const dispatch = useDispatch()
      const location = useLocation()

      useEffect(()=>{
            const handleResize = () => setScreenWidth(window.innerWidth);
            window.addEventListener('resize', handleResize);
      },[])

      const loginHandler = ()=> {
            if(location.pathname.includes(routesName.Login)) {
                  dispatch(setResetLoginPage({ resetLoginPage : true}))
            }
            navigate(routesName.Login)
      }

      return (
                 <React.Fragment>

                        {screenWidth < 1200 && <li className="nav-item">
                              <Link className="nav-link" to="/login">
                                    {title.Login}
                              </Link>
                        </li>}
                        {props.isAjtix < 1200 && <li className="nav-item">
                              <Link className="nav-link" to="/register">
                                    {title.AjtixRegister}
                              </Link>
                        </li>}
                        {!props.isAjtix && <li className="nav-item">
                              <Link className="nav-link" to="/register">
                                    Sign Up
                              </Link>
                        </li>}
                        {!props.isAjtix && <li className="nav-item">
                              <Link className="nav-link" to="/about-us">
                                    About Us
                              </Link>
                        </li>}
                        <li className="nav-item">
                              <Link className="nav-link" to="/faq">
                                    FAQs
                              </Link>
                        </li>
                        <li className={["nav-item",screenWidth < 1200 && "border-bottom-0"].join(" ")}>
                              <Link className="nav-link" to="/contact-us">
                                    Contact Us
                              </Link>
                        </li>
                        {screenWidth >= 1200 && <li className="nav-item border-bottom-0">
                              <button id="btnLogin" className={["nav-link btn btn-primary d-none d-xl-block"].join(" ")} type="button" aria-label="Toggle Login" onClick={loginHandler}>{title.Login}</button>
                        </li>}

                        
                 </React.Fragment>
      )
}

export default UnAuthenticatedMenus;
