import React, { useEffect } from  'react';

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';

// child component
import logoNhsCharitiesTogetherBlue151 from "./../../../../assets/images/logo-nhs-charities-together-blue-151.png";
import logoNhsCharitiesTogetherBlue151a2x from "./../../../../assets/images/logo-nhs-charities-together-blue-151@2x.png";
import logoPenniesBlue120 from "./../../../../assets/images/logo-pennies-blue-120.png";
import logoPenniesBlue120a2x from "./../../../../assets/images/logo-pennies-blue-120@2x.png";
import logoPenniesBlueDarkMode120 from "./../../../../assets/images/logo-pennies-blue-dark-mode-120.png";
import logoPenniesBlueDarkMode120a2x from "./../../../../assets/images/logo-pennies-blue-dark-mode-120@2x.png";

// packages
// Actions & RTK query or mutations
import { useTicketReleaseMutation, useCardIdentifyMutation } from '../../../../Services/modules/BookingModule';
import { setSessionBlockId, setMKey,
      // setWebViewSession 
} from '../../../../Store/User';
import { setBookingHandler } from '../../../../Store/EventDetail';
import { setCharityHandler, setBookingStep, resetForAnimation, setGetBookingDetails } from '../../../../Store/BookingModule';
import { setSpinner,setAlert } from '../../../../Store/UI';

// Constants
import { NameConstants } from '../../../../resources/NameConstants';
import { dateAndTimeFormatHandler }  from "../../../../resources/DateHandler";
import { errors, messages } from '../../../../resources/en';


const OrderSummary = (props) => {

      const browserDetect           = useSelector(state=>state.ui.browserDetect);
      const ct_session_block_id     = useSelector(state=>state.user.ct_session_block_id);
      const bookingModule           = useSelector(state=>state.bookingModule);
      const user                    = useSelector(state=>state.user);
      const eventDetail             = useSelector(state=>state.eventDetail);
      
      const [ticketRelease, { data, isSuccess, isLoading, isFetching, error }]  =    useTicketReleaseMutation();
      const [cardIdentify, { data : cardIdData, isSuccess : cardIdIsSuccess, isLoading : cardIdIsLoading, isFetching : cardIdIsFetching, error : cardIdError }]  =    useCardIdentifyMutation();

      const dispatch = useDispatch();

      useEffect(()=>{
            if(cardIdIsSuccess){
                  props.closeTimerHandler();
                  dispatch(setMKey({mKey : ""}));
                  dispatch(setSessionBlockId({ct_session_block_id : ""}));

                  if(cardIdData.status === "1"){
                        dispatch(resetForAnimation());
                        dispatch(setGetBookingDetails({getBookingDetails: cardIdData }))
                        // commnet becuase using firebase to refetch wallet balance
                        // dispatch(setGetWalletBalance({getWalletBalance:true}));
                        dispatch(setSpinner({status:""}));

                        if(user.ct_web_view_session && window.ReactNativeWebView){
                              // dispatch(setWebViewSession({ct_web_view_session : ""}))
                              window.ReactNativeWebView.postMessage(JSON.stringify({
                                    "stay": true,
                                    "redirect": false,
                                    "redirectTo": "",
                                    "status": 1,
                                    "message": messages.SuccessTicketBooking
                              }));
                        }
                        dispatch(setBookingStep({step: NameConstants.bookingModuleSteps.bookingSuccess }))
                  } 
                  else if(cardIdData.status === "0") {
                        dispatch(resetForAnimation());
                        dispatch(setBookingHandler({isBooking : false}));
                        dispatch(setSpinner({status:""}));
                        if(user.ct_web_view_session && window.ReactNativeWebView){
                              // dispatch(setWebViewSession({ct_web_view_session : ""}))
                              window.ReactNativeWebView.postMessage(JSON.stringify({
                                    "stay": true,
                                    "redirect": false,
                                    "redirectTo": "",
                                    "status": 0,
                                    "message": messages.FailureTicketBooking
                              }));
                        } else {
                              dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:cardIdData.error || cardIdData.message || errors.NotFound404 }))
                        }
                  }
                  
            }

            if(cardIdError){
                  dispatch(setSpinner({status:""}));
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:cardIdError.error || cardIdError.data.error || cardIdError.data.message || errors.NotFound404 }))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[cardIdData, cardIdIsSuccess, cardIdIsLoading, cardIdIsFetching, cardIdError, dispatch])


      useEffect(()=>{
            if(isSuccess){
                  dispatch(setSessionBlockId({ct_session_block_id: ""}));
            }

            // if(error){
                  // dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.data.error || error.data.message || errors.NotFound404 }))
            // }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data, isSuccess, isLoading, isFetching, error, dispatch])

      const onCharityChangeHandler = (event)=> {
            const id = event.target.id;

            const subTotal = charityCalculator();
            if(id==="radioDonateYes"){
                  dispatch(setCharityHandler({charity:true,subTotal:subTotal + NameConstants.charity}))
            } else {
                  dispatch(setCharityHandler({charity:false,subTotal:subTotal}))
            }
      }

      const charityCalculator = ()=>{
            let subTotal = 0;
            subTotal = parseFloat(bookingModule.bookingDetails.price * bookingModule.bookingDetails.quantity);

            if(bookingModule.bookingDetails.isFlexi){
                  subTotal = parseFloat(subTotal + bookingModule.bookingDetails.flexAmount);
            } 
            return subTotal
      }

      const continueOnOrderSummury = ()=>{

            let subTotal = bookingModule.bookingDetails.promocode.status === "valid" ? (bookingModule.bookingDetails.promocode.totalAmount)  :bookingModule.bookingDetails.subTotal
            if(bookingModule.bookingDetails.isFlexi){
                  subTotal = parseFloat(subTotal + bookingModule.bookingDetails.flexAmount);
            } 
            if(bookingModule.bookingDetails.charity){
                  subTotal = parseFloat(subTotal + NameConstants.charity);
            } 

            if (subTotal > 0 && bookingModule.bookingDetails.quantity > 0) {

                  dispatch(setBookingStep({step: NameConstants.bookingModuleSteps.paymentMethod}));

            } 
            else if(bookingModule.bookingDetails.quantity > 0) {
                   
            //       const nhs_session_block_id = localStorage.getItem("nhs_session_block_id");

                  let ticket_type_master_id = bookingModule.performanceInfo.ticket_type_master_id || ""
                  let ticket_type_id = bookingModule.performanceInfo.ticket_type_id

                  const date = dateAndTimeFormatHandler(bookingModule.performanceInfo.date_time,bookingModule.performanceInfo.show_time).split(" ");

                  const data ={

                        event_id: bookingModule.event_id,
                        token: user.ct_access_token,
                        request_client: user.ct_web_view_session ? "app" : "web",
                        coupan_code_id : bookingModule.bookingDetails.promocode.coupan_code_id || "",
                        promocode: bookingModule.bookingDetails.promocode.promocode,
                        performance_id: bookingModule.bookingDetails.showId,
                        discount_amount: bookingModule.bookingDetails.promocode.discount,
                        price: bookingModule.bookingDetails.price,
                        wallet_balance: parseFloat(user.ct_wallet_balance),
                        amount_to_be_paid_by_card: parseFloat(bookingModule.payment.useCardCredit),
                        wallet_balance_used: parseFloat(bookingModule.payment.useWalletCredit),
                        pennies_amount: bookingModule.bookingDetails.charity ? NameConstants.charity : 0,
                        totalAmount:  bookingModule.bookingDetails.subTotal,
                        flexi_price: bookingModule.bookingDetails.flexAmount,
                        is_flexi: bookingModule.bookingDetails.isFlexi,
                        tickets: bookingModule.bookingDetails.quantity,
                        group_availablity: bookingModule.group_availablity,
                        ticket_type_master_id: ticket_type_master_id,
                        ticket_type_id: ticket_type_id,
                        block_id: user.ct_session_block_id,
                        event_name: bookingModule.title,
                        address: eventDetail.eventInfo.address,
                        performance_date_time: bookingModule.performanceInfo.show_time,
                        merchantSessionKey: user.ct_mKey,
                        card_identifier: "",
                        reusable_payment: "false",
                        reusable: "false",
                        get_current_id: "order_summary_view",
                        get_next_id: "card_details_view",
                        apple_pay_id: "",
                        raw_date: date[0],
                        start_time: date[2],
                        ticket_type_name: "",
                        payment_method: bookingModule.payment.paymentMethod,
                        timer: "",
                        session_id : user.ct_web_view_session ? user.ct_web_view_session : "",
                  }

                  dispatch(setSpinner({status:"request"}))
                  dispatch(cardIdentify(data))
            }

      }

      const backOnOrderSummury = ()=>{

            if(ct_session_block_id){

                  let ticket_type_master_id = bookingModule.performanceInfo.ticket_type_master_id || ""
                  ticketRelease({block_id:ct_session_block_id,group_availablity:bookingModule.group_availablity,ticket_type_master_id :ticket_type_master_id});
                  dispatch(setSessionBlockId({ct_session_block_id: ""}));
            }
            
            dispatch(resetForAnimation())
            // dispatch(setBookingStep({step: NameConstants.bookingModuleSteps.chooseDate }));
            // dispatch(setEventId({event_id : bookingModule.event_id,title : bookingModule.title }))
            props.closeTimerHandler();
      }

      let ticketTypes = "";
      let subTotal = 0;
      let displayTotalOnOrderSummury = bookingModule.bookingDetails.subTotal.toFixed(2);
      if(bookingModule.ticketTypes.length > 0) {
            if(bookingModule.group_availablity !==2){
                  ticketTypes = bookingModule.ticketTypes[0];
            }else if (bookingModule.group_availablity ===2){
                  bookingModule.ticketTypes.forEach(el=>{
                        if(el.selected){
                              ticketTypes = el.name;
                        }
                  })
            }
            subTotal = parseFloat(bookingModule.bookingDetails.price*bookingModule.bookingDetails.quantity);
      }

      if(bookingModule.bookingDetails.promocode.status === "valid") {
            let charity = 0;
            if(bookingModule.bookingDetails.charity){
                  charity = NameConstants.charity;
            }
            displayTotalOnOrderSummury = parseFloat(bookingModule.bookingDetails.promocode.totalAmount + bookingModule.bookingDetails.flexAmount + charity).toFixed(2);
      }

      return (
            <div className="bookTicketsStep2__wrapper">

                  <div id="bookTicketsStep2" className={["content content__order-summary d-flex"].join(" ")}>
                        {/* <!-- .content__body in css has min-height value ADD class "min-height-0" to remove initial value --> */}
                        <div className="content__body min-height-0">
                              <h4 className="event__name red">{bookingModule && bookingModule.title}</h4>
                              <p className="event__date">
                                    {dateAndTimeFormatHandler(bookingModule.performanceInfo.date_time,bookingModule.performanceInfo.show_time)}
                              </p>
                              <table className="table table-black-theme table__items border-top">
                                    <tbody>
                                          <tr>
                                                <td className="td-item">{bookingModule.bookingDetails.quantity} {ticketTypes}</td>
                                                <td className="td-amount">£{subTotal.toFixed(2)}</td>
                                          </tr>
                                          {bookingModule.bookingDetails.isFlexi && 
                                          <tr>
                                                <td className="td-item">{bookingModule.bookingDetails.quantity} Flexi Tickets</td>
                                                <td className="td-amount">£{parseFloat(bookingModule.bookingDetails.flexAmount).toFixed(2)}</td>
                                          </tr>}
                                          {bookingModule.bookingDetails.promocode.status === "valid" &&
                                          <tr>
                                                <td className="td-item">{bookingModule.bookingDetails.promocode.promocode} (Voucher)</td>
                                                <td className="td-amount">-£{parseFloat(bookingModule.bookingDetails.promocode.discount).toFixed(2)}</td>
                                          </tr>}
                                    </tbody>
                              </table>
                        </div>
                        {/* <!-- /.content__body --> */}
                        {/* <!-- .content__body in css has min-height value ADD class "min-height-0" to remove initial value --> */}
                        <div className="content__body border-top min-height-0">
                              <p className="p-donate"><strong>Add 50p</strong> in donation for <span className="fw-bold blue">NHS Charities Together</span> & Pennies.
                              We will match your donation to NHS Charities Together!</p>
                              <div className="checkbox-wrapper flex-column flex-md-row">
                                    <div className="custom-control custom-checkbox custom-checkbox__check" style={{marginRight:browserDetect ? "20px":""}}>
                                          <input type="radio" className="custom-control-input" checked={bookingModule.bookingDetails.charity} name="radioDonate" id="radioDonateYes" onChange={onCharityChangeHandler}/>
                                          <label className="custom-control-label" htmlFor="radioDonateYes">Yes, I would like to donate 50p</label>
                                    </div>
                                    <div className="custom-control custom-checkbox custom-checkbox__check">
                                          <input type="radio" className="custom-control-input" checked={!bookingModule.bookingDetails.charity} name="radioDonate" id="radioDonateNo" onChange={onCharityChangeHandler}/>
                                          <label className="custom-control-label" htmlFor="radioDonateNo">No, thank you</label>
                                    </div>
                              </div>
                              <p className="small mb-4">Every penny you give goes to charity: 90% to NHS Charities Together (registered charity no. 1186569) and 10% to Pennies (registered charity no. 1122489)</p>
                              <div className="logos-wrapper">

                                    <img className="logo-nhs-charities" srcSet={`${logoNhsCharitiesTogetherBlue151a2x} 2x`} src={logoNhsCharitiesTogetherBlue151} alt="" style={{marginRight:browserDetect ? "20px":""}}/>
                                    {user.ct_app_dark_mode ? <img className="logo-pennies" srcSet={`${logoPenniesBlueDarkMode120a2x} 2x`} src={logoPenniesBlueDarkMode120} alt="" style={{marginRight:browserDetect ? "20px":""}}/>
                                    : <img className="logo-pennies" srcSet={`${logoPenniesBlue120a2x} 2x`} src={logoPenniesBlue120} alt="" style={{marginRight:browserDetect ? "20px":""}}/>}

                              </div>
                              <br/><br className="d-none d-xxl-block"/>
                        </div>
                        {/* <!-- /.content__body --> */}

                        
                  </div>

                  <div className="content__footer__wrapper">
                        <div className="content__body content__body--total border-top">
                              <div className="subtotal-block">
                                    <div className="label">Total (VAT inc.):</div>
                                    <div className="total-amount">£{displayTotalOnOrderSummury}</div>
                              </div>
                        </div>
                        {/* <!-- /.content__body --> */}
                        <div className="content__footer border-top">
                              <div className="buttons-wrapper gap-0">
                                    <button id="btnBackToStep1" type="button" className="btn__back btn btn-outline-primary w-50" onClick={backOnOrderSummury}>Back</button>
                                    <button id="btnContinueStep3" type="button" className="btn btn-primary w-50" onClick={continueOnOrderSummury}>Book now</button>
                              </div>
                        </div>
                        {/* <!-- /.content__footer --> */}
                  </div>                              
            </div>
      )
}

export default OrderSummary;
