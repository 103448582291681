import React, { useState } from 'react'

// external css
// redux and react hooks
import { useDispatch } from 'react-redux';

// child component
// packages
// Actions & RTK query or mutations
import { setConsent } from '../../Store/User';
// Constants

const CookiesConsentPage = () => {

      const [settings, setSettings]             =     useState(false)
      const [marketing, setMarketing]           =     useState(true)
      const [advertising, setAdvertising]       =     useState(true)
      const [essential, setEssential]           =     useState(true)
      const dispatch                      =     useDispatch()

      const acceptCookiesHandler = () => {
            dispatch(setConsent({
                  essential : true,
                  advertising: true,
                  marketing: true
             }))
      }

      const rejectCookiesHandler = ()=> {
            dispatch(setConsent({
                  essential : true,
                  advertising: false,
                  marketing: false
            }))
      }

      const settingsHandler = ()=> {
            setSettings(!settings)
      }

      const closePreferencesSettingsHandler = () =>{
            setSettings(false)
      }

      const changeCheckBoxHandler = (event)=> {
            const name = event.target.name
            const value = (event.target.value === "false"  ||event.target.value  === false )? true : false

            if(name === "checkMarketing") {
                  setMarketing(value)
            } else if(name === "checkAdvertising") {
                  setAdvertising(value)
            } else if(name === "checkEssential") {
                  setEssential(value)
            }
      }

      const savePreferenceHandler = () => {
            dispatch(setConsent({
                  essential : essential,
                  advertising: advertising,
                  marketing: marketing
             }))
            setSettings(false)
      }

      return (
                  <>
                        <div className="modal modal__fw modal__terms-and-conditions modal-cookies show" style={{"display":"block"}} >
                              <div className="modal-dialog">
                                    <div className="modal-content">
                                          <div className="modal-body">
                                                
                                                <div className="cookie-banner">
                                                      {!settings ? 
                                                      <div className="cookie-banner__message width-container cookie-home-page">

                                                            <div className="row row-1">
                                                                  <div className="grid-column-two-thirds">
                                                                        <h2 className="heading">
                                                                              Cookies on Central <span className='red'>Tickets</span>
                                                                        </h2>
                                                                        <div className="cookie-banner__content">
                                                                              <p className="body">We use some essential cookies to make this service work. We’d also like to use analytics cookies so we can understand how you use the service and make improvements.</p>
                                                                        </div>
                                                                  </div>
                                                            </div>

                                                            <div className='row row-2'>
                                                                  <div className="form-group__cta d-flex">
                                                                        <div className="button-wrapper">
                                                                              <button className="btn btn-xl-lg btn-primary mb-0" onClick={acceptCookiesHandler}>
                                                                                    Accept
                                                                              </button>
                                                                              <button className="btn btn-xl-lg btn-primary mb-0 reject" onClick={rejectCookiesHandler}>
                                                                                    Reject
                                                                              </button>
                                                                        </div>
                                                                        <div className='button-wrapper text-button-wrapper'>
                                                                              <button aria-label="More options" className="more-options" type="button" onClick={settingsHandler}>More options</button>
                                                                        </div>
                                                                  </div>
                                                            </div>

                                                      </div> : 
                                                      <div className="cookie-banner__message width-container cookie-settings">
                                                            <div className='row-container'>
                                                                  <h2 className="heading">
                                                                        Cookie preferences
                                                                        <button type="button" className="btn-close btn-close__custom btn-close__absolute" data-bs-dismiss="modal" aria-label="Close" onClick={closePreferencesSettingsHandler}>
                                                                        </button>
                                                                  </h2>
                                                            </div>
                                                            <div className='row-container'>
                                                                  <div className='content'>
                                                                        <div className="custom-control custom-checkbox custom-checkbox__radio">
                                                                              <input type="checkbox" className="custom-control-input" id="checkMarketing" name="checkMarketing" checked={marketing} value={marketing}
                                                                                    onChange={changeCheckBoxHandler}
                                                                              />
                                                                              <label className="custom-control-label" htmlFor="checkMarketing">
                                                                                    <h5>Marketing</h5>
                                                                                    To understand user behaviour in order to provide you with a more relevant browsing experience or personalise the content on our site. For example, we collect information about which pages you visit to help us present more relevant information.
                                                                              </label>
                                                                        </div>
                                                                        <div className="custom-control custom-checkbox custom-checkbox__radio">
                                                                              <input type="checkbox" className="custom-control-input" checked={advertising} id="checkAdvertising" name="checkAdvertising" value={advertising}
                                                                              onChange={changeCheckBoxHandler}
                                                                              />
                                                                              <label className="custom-control-label" htmlFor="checkAdvertising">
                                                                                    <h5>Advertising</h5>
                                                                                    To personalise and measure the effectiveness of advertising on our site and other websites. For example, we may serve you a personalised ad based on the pages you visit on our site.
                                                                              </label>
                                                                        </div>
                                                                        <div className="custom-control custom-checkbox custom-checkbox__radio">
                                                                              <input type="checkbox" className="custom-control-input" id="checkEssential" checked={essential} disabled name="checkEssential" value="false"
                                                                              onChange={changeCheckBoxHandler}
                                                                              />
                                                                              <label className="custom-control-label" htmlFor="checkEssential">
                                                                                    <h5>Essential</h5>
                                                                                    We use browser cookies that are necessary for the site to function as intended. For example, we store your website data collection preferences so we can honour them if you return to our site. You can disable these cookies in your browser settings, but if you do the site may not function as intended.
                                                                              </label>
                                                                        </div>
                                                                        <div className="form-group__cta d-flex">
                                                                              <button className="btn btn-xl-lg btn-primary mb-0" onClick={savePreferenceHandler}>
                                                                                    Save
                                                                              </button>
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>}
                                                </div>

                                          </div>
                                          {/* <!-- /.modal-body --> */}
                                    </div>
                                    {/* <!-- /.modal-content --> */}
                              </div>
                              {/* <!-- /.modal-dialog --> */}
                        </div>
                        {/* <!-- modal #modalTermsAndConditions --> */}
                        <div className="modal-backdrop fade show" style={{"top": "0px"}}></div>
                        
                  </>
      )
}

export default CookiesConsentPage;
