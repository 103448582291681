/* eslint-disable */
import React, { useState, useEffect } from 'react'

// external css
// redux and react hooks
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// child component
import CentralticketsLogoColour from "../../assets/images/centraltickets-logo-colour.svg";
import TfmLogoColor from "../../assets/images/TFM-logo-colour.png";
import AjtixLogo from "../../assets/images/Ajtix-logo.png"
import AuthenticatedMenus from './AuthenticatedMenus/AuthenticatedMenus';
import UnAuthenticatedMenus from './UnAuthenticatedMenus/UnAuthenticatedMenus';

// packages
// Actions & RTK query or mutations
import { setCTRedirectUrl } from '../../Store/User'
import { setShowLoginPopup } from "../../Store/UI"
import { setFetchListing } from "../../Store/CurrentListing"
import { routesName } from '../../resources/RoutesName';
import { Config } from '../../Config';

// Constants


const Header = () => {

      const [screenWidth, setScreenWidth] = useState(window.innerWidth);
      const [showLogin,setShowLogin]      =     useState(false);
      const [showMenu,setShowMenu]        =     useState(false);
      const [logoOnly,setLogoOnly]        =     useState(false);
      const [covidHeader,setCovidHeader]  =     useState(false);
      const [landingPage,setLandingPage]  =     useState(false);
      const user                          =     useSelector(state=>state.user)
      const {showLoginPopup, refreshListing }            =     useSelector(state=>state.ui)

      const location                      =     useLocation();
      const dispatch                      =     useDispatch();
      const navigate                      =     useNavigate();



      useEffect(() => {
            if(location.pathname.includes("landing-page")) {
                  setLandingPage(true)
            } else {
                  setLandingPage(false)
            }
            if((location.pathname === "/")&& !user.auth){
                  setCovidHeader(true)
            } else {
                  setCovidHeader(false)
            }
            if(location.pathname.includes("uptake_request")|| location.pathname.includes("uptake_request_by_venue") || location.pathname.includes("uptake_request_by_venue_all_events") ||  location.pathname.includes("no-working-page") || location.pathname.includes("underrepresented_communities") || location.pathname.includes("landing-page") || location.pathname.includes("get_feedback_report")){
                  setLogoOnly(true)
            } else if(logoOnly){
                  setLogoOnly(false)
            }
            setShowMenu(false)
            setShowLogin(false)
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [location.pathname,logoOnly])


      // remove my-wallet from ct_redirect_url url to prevent user to redirect wallet page after login
      useEffect(()=>{

            if(location.pathname !== "/login" && user.ct_redirect_url === "/my-wallet"){
                  dispatch(setCTRedirectUrl({ct_redirect_url : ''}))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[location.pathname])

      // Show login menu if routes is my-wallet and user is not authenticated
      useEffect(()=>{
            if(showLoginPopup) {
                  if(screenWidth > 1200){
                        showLoginHandler()
                  } else {
                        showMenuHandler()
                  }
                  
                  dispatch(setShowLoginPopup({showLoginPopup : false}))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[showLoginPopup])

      // check the windows width and change windows width on resize 
      useEffect(()=>{
            const handleResize = () => setScreenWidth(window.innerWidth);
            window.addEventListener('resize', handleResize);
      },[])

      const showLoginHandler = ()=>{
            setShowLogin(!showLogin)
      }

      const showMenuHandler = ()=>{
            setShowMenu(!showMenu)
      }

      const stickyHeaderHandler = ()=> {
            dispatch(setCTRedirectUrl({ct_redirect_url : '/my-wallet'}))
            navigate(routesName.Login)
            // dispatch(setShowLoginPopup({showLoginPopup : true}))
      }

      const refreshListingHandler = ()=> {
            dispatch(setFetchListing({fetchListing : true}))
      }

      const getDateForTrustpilotLogo = ()=>{
            const date = Date.now()
            return `Trustpilot_score_banner_white.png?v=${date}`
      }

      let trustpilotLogo = "Trustpilot_score_banner_white.png?v=1";
      trustpilotLogo = getDateForTrustpilotLogo()


      let borderBottom = false
      if(location.pathname){
            borderBottom = location.pathname === "/" && user.domain !== Config.DOMAIN_CONSTANT_AJTIX
      }

      let logo = CentralticketsLogoColour
      if(user.domain === Config.DOMAIN_CONSTANT_AJTIX) {
            logo = AjtixLogo
      } else if(user.domain === Config.DOMAIN_CONSTANT_TFM) {
            logo = TfmLogoColor
      }


      return (
                  <header className={["main-header d-flex flex-column align-items-center position-fixed w-100",showMenu? "show-menu" : "", user.domain === Config.DOMAIN_CONSTANT_AJTIX && "ajtix-main-header"].join(" ")} style={{borderBottom : borderBottom ? "0px" : ""}}>
                        {/* {((covidHeader || landingPage) && user.domain !== Config.DOMAIN_CONSTANT_AJTIX && user.domain !== Config.DOMAIN_CONSTANT_TFM) &&
                                    
                                    <div className="trustpilot-header fw-bold">
                                          <div className="col">
                                                <a href={URL.Trust_Pilot_Centraltickets_Page} rel="noreferrer" target="_blank">
                                                      <img className="Trustpilot-reviews" alt="Trustpilot reviews" src={`${URL.Image_Base_Url}Trustpilot/${trustpilotLogo}`}></img>
                                                </a>
                                          </div>
                                    </div>
                                    
                         } */}
                        <div className="container-fluid">
                              <nav className="navbar main-header__navbar navbar-expand-xl align-items-center py-0">
                                    <Link className={["navbar-brand p-0",logoOnly ? "mx-auto": "me-auto"].join(" ")} to="/">
                                          <span className="logo-wrapper">
                                                <img className="navbar-brand__logo" src={logo} alt="CentralTickets"/>
                                          </span>
                                    </Link>

                                    {!logoOnly &&
                                    <>
                                          <button className={["btn navbar-toggler navbar-toggle__custom hamburger d-xl-none",showMenu && "active"].join(" ")} type="button" aria-label="Toggle Menu" onClick={showMenuHandler}>
                                                <span className="navbar-toggle__hamburger"></span>
                                          </button>

                                          <div className="main-header__main-menu" id="navbarMainMenu" style={{paddingBottom : screenWidth < 1200 && "0px"}}> 
                                                <ul className="navbar-nav mb-0">
                                                      {user.auth ?
                                                      <AuthenticatedMenus/>:
                                                      <UnAuthenticatedMenus showMenu={showMenu} isAjtix={user.domain === Config.DOMAIN_CONSTANT_AJTIX} showMenuHandler={showMenuHandler}/> 
                                                      }
                                                </ul>
                                          </div>
                                    </>}
                                    {/* <!-- / #navbarMainMenu --> */}
                              </nav>
                        </div>
                        {/* <!-- /.container-fluid --> */}

                        {/* Added Header untill got proper solution from Designer */}
                        {(covidHeader && user.domain !== Config.DOMAIN_CONSTANT_AJTIX) &&
                        <div data-scroll-section className="container-fluid container__notification container__notification--alert d-flex align-items-center justify-content-center">
                              <div data-scroll>
                                    {/* <Link className="fw-bold sticky-header-link" to={routesName.MyWallet}>
                                          Get 25% extra credit by topping up your wallet
                                    </Link> */}
                                    <div className="fw-bold sticky-header-link" 
                                    onClick={stickyHeaderHandler}
                                    >
                                          10% free wallet credit with first top-up (up to £10 - terms apply)
                                    </div>
                              </div>
                        </div> }

                        {(refreshListing &&  location.pathname.includes(routesName.CurrentListing))  && <div data-scroll-section className="container-fluid container__notification container__notification--alert d-flex align-items-center justify-content-center">
                              <div data-scroll>
                                   
                                    <div className="fw-bold sticky-header-link" 
                                    onClick={refreshListingHandler}
                                    >
                                          updated shows & dates available - click to refresh
                                    </div>
                              </div>
                        </div>}
                  </header>
      )
}

export default Header;
