import React, { useEffect, useState, useLayoutEffect } from 'react'

// external css
// redux and react hooks
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

// child component
import BookingModule from '../BookingModule/BookingModule'

// packages
// Actions & RTK query or mutations
import { useLazyGetSessionKeyDetailsQuery } from '../../../Services/modules/BookingModule'
import { setWebViewSession, changeToken, changeCtAcessRefreshToken, setWalletBalance, setTomMember, setDarkMode } from '../../../Store/User'
import { setEventsData, setBookingHandler } from '../../../Store/EventDetail'
import { setGetBookingDetails, setBookingStep } from "../../../Store/BookingModule"
import { setAlert } from '../../../Store/UI'
import { setSpinner } from '../../../Store/UI'

// import { setGetBookingDetails, setBookingStep } from "../../../Store/BookingModule"

// Constants
import { errors, messages } from '../../../resources/en'
import { NameConstants } from '../../../resources/NameConstants'

const BookingModuleWeb = () => {

      const [ eventId, setEventId ]       =     useState("")
      const user                          =     useSelector(state=>state.user)
      const { token }                     =     useParams()
      const dispatch                      =     useDispatch();
      const { pathname,search }           =     useLocation();
      const navigate                      =     useNavigate();
      
      const [getSessionKeyDetails, { data, isSuccess, isLoading, isFetching, error }]  =    useLazyGetSessionKeyDetailsQuery();

      useLayoutEffect(()=>{
            const searchParams = new URLSearchParams(search);  
            const darkMode = searchParams.get('darkMode')?.toString()
            if(darkMode === "true") {
                  document.body.classList.add('dark-mode');
                  dispatch(setDarkMode({ct_app_dark_mode : true}))
            } else {
                  document.body.classList.remove('dark-mode');
                  dispatch(setDarkMode({ct_app_dark_mode : false}))
            }

            if(!pathname.includes("payment-success") && !pathname.includes("payment-failure")){
                  dispatch(changeToken({ct_access_token : ""}))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      useEffect(()=>{   
            dispatch(setSpinner({status : "request"}));
            getSessionKeyDetails({ session_id : token })
            
      },[getSessionKeyDetails, token, dispatch])

      useEffect(()=>{
            if(isSuccess){
                  // Call get User Wallet Balance API  to update wallet balance
                  if(data.status === "1"){

                        if(data.data.is_set_cookie === "0" || user.ct_web_view_session === token){
                              setEventId(data.data.event_id)
                              dispatch(setWebViewSession({ct_web_view_session : token }))
                              // dispatch(changeToken({ct_access_token : data.data.token}))
                              dispatch(changeCtAcessRefreshToken({ ct_access_token : data.data.token, ct_refresh_token : data.data.refresh_token}))
                              dispatch(setTomMember({ct_is_top_member : data.data.is_top_member || 0}))  
                              dispatch(setEventsData(data.data))
                              dispatch(setBookingHandler({isBooking : true}))
                              dispatch(setWalletBalance({ ct_wallet_balance : parseFloat(data.data.wallet_balance)}))
                              
                              if(pathname.includes("payment-success") || pathname.includes("payment-failure")) {
                                    const searchParams = new URLSearchParams(search);  
                                    const bookingStatus = pathname.includes("/payment-success") ? "success" : "failure";
                                    const booking_id = searchParams.get('booking_id') || "";
                                    const client = searchParams.get('client');
                                    const message = bookingStatus === "success" ?  messages.SuccessTicketBooking : messages.FailureTicketBooking;

                                    if(client === "app"){
                                          // window.ReactNativeWebView.postMessage(message);
                                          if(window.ReactNativeWebView){
                                                window.ReactNativeWebView.postMessage(JSON.stringify({
                                                      "stay": true,
                                                      "redirect": false,
                                                      "redirectTo": "",
                                                      "status": bookingStatus === "success" ? 1 : 0,
                                                      "message": message
                                                }));
                                          }
                                          else{
                                                if(bookingStatus === "success"){
                                                      dispatch(setGetBookingDetails({getBookingDetails: { booking_id : booking_id } }))
                                                      dispatch(setBookingStep({step:NameConstants.bookingModuleSteps.bookingSuccess}))
                                                      dispatch(setBookingHandler({isBooking:true}));
                                                }
                                                else {

                                                      dispatch(setAlert({model:true,title:messages.modalAlertTitle,message: message}))
                                                }
                                          }
                                    }
                                    
                                    dispatch(setSpinner({status : ""}));
                                    navigate(`/booking-module/${token}`, { replace: true });
                              }
                        }
                  }
                  else if(data.status === "0"){
                        // dispatch(setSpinner({status : ""}));
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.errors || data.error || data.message || errors.NotFound404}));
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404 }))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data, isSuccess, isLoading, isFetching, error, dispatch])
     
      return (
            <div id='WebViewBookingModule'>
                  <BookingModule show={true} eventId={eventId} />
            </div>
      )
}

export default BookingModuleWeb

