import React, { useEffect } from 'react'

// external css

// small class
import ticketFlexiGreen1 from "./../../../assets/images/tickets/ticket-uptake-venues-green1.svg";

import ticketFlexiRed from "./../../../assets/images/tickets/ticket-faq-big-heading1-red.svg";
import ticketFlexiAquamarineSm from "./../../../assets/images/tickets/ticket-uptake-venues-aquamarine-sm.svg";

import ticketFlexiOrange1 from "./../../../assets/images/tickets/ticket-faq-big-heading1-orange.svg";
import ticketFlexiBlue1 from "./../../../assets/images/tickets/ticket-my-bookings-big-heading1-blue.svg";

// redux and react hooks
// child component
// packages
// Actions & RTK query or mutations

// Constants
import { URL } from '../../../resources/Url';

const WalletTerms = ({closeTermsModal}) => {


      useEffect(()=>{
            // getFlexi();
      },[]);

      return (

                  <React.Fragment>
                        <div className="modal modal__fw modal__terms-and-conditions slideInDown show" id="modalFlexiTermsAndConditions" data-bs-backdrop="static" tabIndex="-1" aria-hidden="false">
                              <div className="modal-dialog">
                                    <div className="modal-content">
                                          <div className="modal-body">
                                                <button type="button" className="btn-close btn-close__custom btn-close__absolute" 
                                                // data-bs-dismiss="modal" aria-label="Close" 
                                                onClick={closeTermsModal}>
                                                </button>
                                                <div className="content d-flex flex-column flex-xl-row"  data-scroll-container>
                                                      {/* <!-- /.content__body --> */}
                                                      {/* Added class  (content-wallet-terms-modal)*/}
                                                      <div className="content__body content-wallet-terms-modal">
                                                            <h2 className="heading" style={{"textAlign":"center"}}>Wallet Terms <br />and Conditions</h2>
                                                            {/* Added pictures  */}
                                                            <picture>
                                                                  <img data-scroll className="ticket ticket-blue" src={ticketFlexiBlue1} alt=""/>
                                                            </picture>
                                                            <picture>
                                                                  <img data-scroll className="ticket ticket-green1" src={ticketFlexiGreen1} alt=""/>
                                                            </picture>
                                                            <picture> 
                                                                  <img data-scroll className="ticket ticket-AquamarineSm" src={ticketFlexiAquamarineSm} alt=""/>
                                                            </picture>
                                                            <picture>
                                                                  <img data-scroll="" className="ticket ticket-red d-lg-block" src={ticketFlexiRed} alt=""/>
                                                            </picture>
                                                            <picture>
                                                                  <img data-scroll="" className="ticket ticket-orange1 d-lg-block" src={ticketFlexiOrange1} alt=""/>
                                                            </picture>
                                                            {/* Added pictures  */}
                                                            {/* {data && <div dangerouslySetInnerHTML={{ __html: data.data }}></div> } */}
                                                            <div>
                                                            <div className="terms_web">
   
                                                                  <ol start="4">
                                                                  <li>
                                                                        <ol>
                                                                              <li>
                                                                                    <span>
                                                                                          <span>
                                                                                                <span>
                                                                                                      Central Tickets Limited operate a credit wallet system that
                                                                                                      allows members to accrue ‘credit’ and, where members can pay
                                                                                                      for credit that can be redeemed against the cost of seat
                                                                                                      filling bookings. Members are entitled to purchase account
                                                                                                      credit which is then retained within your designated Wallet
                                                                                                      however, once credited members are not able to seek a refund.
                                                                                                </span>
                                                                                          </span>
                                                                                    </span>
                                                                              </li>
                                                                              <li>
                                                                                    <span>
                                                                                          <span>
                                                                                                <span>
                                                                                                      Members with credit may cover the cost of event seats at any
                                                                                                      events listed under the “Shows” tab.
                                                                                                </span>
                                                                                          </span>
                                                                                    </span>
                                                                              </li>
                                                                              <li>
                                                                                    <span>
                                                                                          <span>
                                                                                                <span>
                                                                                                      Credit balances cannot be used to purchase tickets for events
                                                                                                      operated by the third-party companies we partner with. Credits
                                                                                                      can only be used to purchase tickets for shows sold through
                                                                                                      our website via our&nbsp;
                                                                                                      <a href={`${URL.SITE_BASE_URL}current-listings`}
                                                                                                            target="_blank" rel='noreferrer'>
                                                                                                            Shows
                                                                                                      </a>&nbsp;
                                                                                                      tab and cannot be used for purchases made via our links to
                                                                                                      either *<a href="https://centraltickets.ticketswitch.com/"
                                                                                                      target="_blank" rel='noreferrer'>Ingresso</a> or *
                                                                                                      <a href="https://centraltickets.tixculture.com/"
                                                                                                      target="_blank" rel='noreferrer'>Encore/Today Tix Group.</a>
                                                                                                      For the avoidance of doubt, a seat filling opportunity is any
                                                                                                      show that we manage directly and as shown within the inventory
                                                                                                      &nbsp;<a href={`${URL.SITE_BASE_URL}current-listings`}
                                                                                                      target="_blank" rel='noreferrer'>
                                                                                                      Shows</a>&nbsp;tab of our website. It cannot be used on any tickets via the
                                                                                                      West End Offers tab, which is a third-party and links to
                                                                                                      <a href="https://centraltickets.tixculture.com/"
                                                                                                      target="_blank" rel='noreferrer'>Today Tix Group</a>.
                                                                                                </span>
                                                                                          </span>
                                                                                    </span>
                                                                              </li>
                                                                              <li>
                                                                                    <span>
                                                                                          <span>
                                                                                                <span>* Links included to help give a visual understanding of the
                                                                                                      distinction between the shows we manage directly and the
                                                                                                      listed providers excluded from the Credit Wallet.
                                                                                                </span>
                                                                                          </span>
                                                                                    </span>
                                                                              </li>
                                                                              <li>
                                                                                    <span>
                                                                                          <span>
                                                                                                <span>For first-time top-ups of £100 or more, we’re happy to offer an additional 10% in bonus credit. To qualify, members simply need to make a one-off top-up of £100 in a single transaction. This promotional credit is capped at a maximum of £10 and can be used exclusively on purchases within our platform. Please note that if an account is closed and a wallet balance is refunded for any reason, any bonus credit from this offer will be forfeited, as the eligibility criteria are no longer met. This offer is a one-time benefit, available only on your initial top-up of £100 or more, so you can make the most out of your first experience with us.
                                                                                                </span>
                                                                                          </span>
                                                                                    </span>
                                                                              </li>
                                                                        </ol>
                                                                  </li>
                                                                  </ol>
                                                                  <p className="spacing_manage" style={{"marginLeft": "42.55pt"}}>&nbsp;</p>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                      {/* <!-- /.content__body --> */}
                                                </div>
                                                {/* <!-- /.content --> */}

                                          </div>
                                          {/* <!-- /.modal-body --> */}
                                    </div>
                                    {/* <!-- /.modal-content --> */}
                              </div>
                              {/* <!-- /.modal-dialog --> */}
                        </div>
                        {/* <!-- modal #modalTermsAndConditions --> */}
                        <div className="modal-backdrop fade show" style={{"top": "91px"}}></div>
                  </React.Fragment>
      )
}

export default WalletTerms;
