import { createSlice } from '@reduxjs/toolkit'

const initialState = { 
      auth:false,
      ct_access_token:null,
      ct_refresh_token:null,
      ct_register_token:null,
      ct_access_name:'',
      ct_access_email:'',
      ct_access_mobilenumber:'',
      ct_is_verified:'false',
      ct_name_length_short : false,
      ct_firebase_key:'',
      ct_reactive:"0",
      ct_referral:"",
      ct_mKey : "",
      ct_session_block_id : "",
      ct_app_dark_mode : false,
      ct_wallet_balance : 0,
      ct_web_view_session : "",
      ct_access_needs : false,
      ct_is_top_member : 0,
      ct_redirect_url : "",
      ct_old_user_verification_code : "",
      ct_wallet_timestamp : null,
      ct_show_update_timestamp : 0,
      ct_registration_type : "",
      ct_gift_voucher : "",
      ct_surevy_redirect : "",
      ct_user_input_fields : null,
      ct_is_change_password : false,
      domain : "",
      consent : {
            essential : false,
            advertising: false,
            marketing: false
      }
}


const slice = createSlice({
      name: 'user',
      initialState: initialState,
      reducers: {
            changeToken: (state, { payload: { ct_access_token } }) => {
                  if (typeof ct_access_token !== 'undefined') {
                        state.ct_access_token = ct_access_token
                  }
            },
            changeCtAcessRefreshToken: (state, { payload: { ct_access_token, ct_refresh_token } }) => {
                  state.ct_access_token = ct_access_token
                  state.ct_refresh_token = ct_refresh_token || null
            },
            login(state,{ payload }){
                  state.auth = true;      
                  state.ct_access_token = payload.token;
                  state.ct_refresh_token =  payload.refresh_token
                  state.ct_access_name = payload.userInfo.fname +" "+ payload.userInfo.lname;
                  state.ct_access_email = payload.userInfo.email;
                  state.ct_access_mobilenumber = payload.userInfo.mobilenumber || "";
                  state.ct_is_verified = payload.userInfo.is_verified.toString();
                  state.ct_firebase_key = payload.userInfo.firebase_key;
                  state.ct_reactive    = payload.userInfo.reactive
                  state.ct_register_token = ""
                  state.ct_registration_type = ""
                  state.ct_gift_voucher = ""
                  state.ct_is_top_member = payload.userInfo.is_top_member
                  state.ct_wallet_balance = parseFloat(payload.userInfo.wallet_balance) || 0
                  state.ct_name_length_short  = payload.name_length_short
            },
            logout(state, { payload }) {

                  state.auth = false
                  state.ct_access_token = null
                  state.ct_refresh_token = null
                  state.ct_access_name = ""
                  state.ct_access_email = ""
                  state.ct_access_mobilenumber = ""
                  state.ct_is_verified = 'false'
                  state.ct_firebase_key = ''
                  state.ct_reactive    = "0"
                  state.ct_register_token = ""
                  state.ct_registration_type = ""
                  state.ct_gift_voucher = ""
                  state.ct_is_top_member = 0
                  state.ct_wallet_balance = 0
                  state.ct_old_user_verification_code = ""
                  state.ct_name_length_short = false
                  state.ct_wallet_timestamp = null
                  state.ct_surevy_redirect = ""
                  state.ct_user_input_fields = null

                  state.ct_redirect_url = ""
                  state.ct_is_change_password = false
            },
            setInfoPartialRegistration(state, { payload }) {
                  state.ct_old_user_verification_code = "" // to clear ct old user verification code which fetched by getLinkResponse API
                  state.auth = payload.auth
                  state.ct_access_token = payload.ct_access_token
                  state.ct_refresh_token = payload.ct_refresh_token
                  state.ct_register_token = payload.ct_register_token
                  state.ct_access_name = payload.ct_access_name
                  state.ct_access_email = payload.ct_access_email
                  state.ct_access_mobilenumber = payload.ct_access_mobilenumber
                  state.ct_is_verified = payload.ct_is_verified.toString()
                  state.ct_firebase_key = payload.ct_firebase_key
                  state.ct_reactive    = payload.ct_reactive
                  state.ct_is_top_member = payload.ct_is_top_member
                  state.ct_wallet_balance = payload.ct_wallet_balance
                  state.ct_name_length_short  = payload.name_length_short || false
            },
            loginAfterCompleteRegistration(state, { payload }) {
                  state.auth = payload.auth
            },
            setCtRegisterToken(state, { payload }){
                  state.ct_register_token = payload.ct_register_token;
                  state.ct_referral = "";
            },
            setSessionBlockId(state, { payload }) {
                  state.ct_session_block_id = payload.ct_session_block_id
            },
            setReferral(state, {payload}) {
                  state.ct_referral = payload.referral;
            },
            setMKey (state, { payload }) {
                  state.ct_mKey = payload.mKey
            },
            setWalletBalance (state, { payload }) {
                  state.ct_wallet_balance = payload.ct_wallet_balance;
            },
            setReactiveAccount(state,{ payload }){
                  state.ct_reactive = payload.reactive;
            },
            setWebViewSession(state, { payload }) {
                  state.ct_web_view_session = payload.ct_web_view_session
            },
            setAccessNeeds(state, { payload }) {
                  state.ct_access_needs = payload.ct_access_needs
            },
            setMobileNumber(state, { payload }) {
                  state.ct_access_mobilenumber = payload.ct_access_mobilenumber
            },
            setCtAccessEmail(state, { payload }) {
                  state.ct_access_email = payload.ct_access_email
            },
            setCTRedirectUrl(state, { payload }) {
                  state.ct_redirect_url = payload.ct_redirect_url
            },
            setOldUserFetchedDetails(state,{ payload }){
                  state.ct_access_name = payload.fname +" "+ payload.lname;
                  state.ct_access_email = payload.email;
                  state.ct_access_mobilenumber = payload.mobilenumber || "";
                  state.ct_old_user_verification_code = payload.code
            },
            updateNameIfIsShort(state, { payload }) {
                  state.ct_access_name = payload.fname + " " + payload.lname
                  state.ct_name_length_short = payload.ct_name_length_short
            },
            setCtWalletTimeStamp(state, { payload }) {
                  state.ct_wallet_timestamp = payload.ct_wallet_timestamp
            },
            setTomMember(state, { payload }) {
                  state.ct_is_top_member = payload.ct_is_top_member
            },
            setShowUpdateTimestamp(state, { payload }) {
                  state.ct_show_update_timestamp = payload.ct_show_update_timestamp
            },
            setRegistrationType(state,  { payload }) {
                  state.ct_registration_type = payload.ct_registration_type
            },
            setGiftVoucher(state, { payload }) {
                  state.ct_gift_voucher = payload.ct_gift_voucher
            },
            setCtSurveyRedirect(state, { payload }) {
                  state.ct_surevy_redirect = payload.ct_surevy_redirect
            },
            setCtUserInputFields(state, { payload }) {
                  state.ct_user_input_fields = payload.ct_user_input_fields
            },
            setDomain(state, { payload }) {
                  state.domain = payload.domain
            },
            setIsChangePassword(state, { payload }){
                  state.ct_is_change_password = payload.ct_is_change_password
            },
            setDarkMode(state, { payload }) {
                  state.ct_app_dark_mode = payload.ct_app_dark_mode
            },
            setConsent (state, { payload }) {
                  state.consent.essential = payload.essential
                  state.consent.advertising = payload.advertising
                  state.consent.marketing = payload.marketing
            },
            reset: ()=> initialState
      },
      
})



export const { login, logout, setDefaultToken, setCtRegisterToken, setReferral, setMKey, setSessionBlockId, 
      setWalletBalance, setReactiveAccount, setWebViewSession, setAccessNeeds, changeToken, 
      changeCtAcessRefreshToken, setMobileNumber, setCtAccessEmail, setCTRedirectUrl, setInfoPartialRegistration, 
      loginAfterCompleteRegistration, setOldUserFetchedDetails, updateNameIfIsShort, setCtWalletTimeStamp, setTomMember, 
      setShowUpdateTimestamp, setRegistrationType, setGiftVoucher, setCtSurveyRedirect, setCtUserInputFields, setDomain, 
      setIsChangePassword, setDarkMode, setConsent,
      reset 

} = slice.actions

export default slice.reducer
