import React, { useState, useEffect } from 'react'

// external css
// redux and react hooks
import { useSelector, useDispatch } from 'react-redux';

// child component
import { BackArrow } from "./index";

// packages
import Recaptcha from "react-recaptcha";

// Actions & RTK query or mutations
import { useContactWebMutation } from '../../../Services/modules/ContactUs';
import { setAlert } from '../../../Store/UI';
import { reset, setModalStatus } from '../../../Store/ContactUs';

// Constants
import { contactUsValidationRules } from '../../../Services';
import { NameConstants } from '../../../resources/NameConstants';
import { actions, placeholders, label, errors, messages } from '../../../resources/en';
import { Config } from '../../../Config';

const intialState = {
      [NameConstants.Name] : { value : "", error : false, errorMessage : ""},
      [NameConstants.Email] : { value : "", error : false, errorMessage : ""},
      [NameConstants.PhoneNumber] : { value : "", error : false, errorMessage : ""},
      [NameConstants.Message] : { value : "", error : false, errorMessage : ""},
}

const OrganizerSimpleForm = () => {

      let recaptchaInstance;

      const [verify,setVerify]      =     useState({value:false,error:false,message:messages.UntickCaptcha});

      const [state , setState]      =     useState(intialState);
      const browserDetect           =     useSelector(state=>state.ui.browserDetect);
      const dispatch                =     useDispatch();

      const [contactWeb, { data, isSuccess, error, isLoading }] = useContactWebMutation();

      useEffect(()=>{

            if(isSuccess) {
                  if(data.status === "1"){
                        // dispatch(setAlert({model:true,title:messages.modalSuccessTitle, message : data.message || data.error || errors.NotFound404}));
                        resetRecaptcha();
                        dispatch(reset())
                        dispatch(setModalStatus({status : "messageReceived"}))
                  } else if(data.status === "0"){
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.errors || data.error || data.message || errors.NotFound404}));
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404}));
            }
            
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data, isSuccess, isLoading, error, dispatch])

     
      const handleChange = (event) => {

            const name = event.target.name;
            let value = event.target.value;

            if(name === NameConstants.PhoneNumber){
                  value = event.target.value.replace(/\D/g, '');
            }

            setState(prevState => ({
                        ...prevState,
                        [name] : {...prevState[name],value:value}
                  })
            )
      }

      const onfocusHandler = ()=>{

            setState(prevState=>{

                  return {
                        ...prevState,
                        [NameConstants.Name]:{...prevState[NameConstants.Name],error:false,errorMessage:""},
                        [NameConstants.Email]:{...prevState[NameConstants.Email],error:false,errorMessage:""},
                        [NameConstants.PhoneNumber]:{...prevState[NameConstants.PhoneNumber],error:false,errorMessage:""},
                        [NameConstants.Message]:{...prevState[NameConstants.Message],error:false,errorMessage:""}
                  }
            })

            setVerify(prevState=>{
                  return {
                        ...prevState,
                        error:false
                  }
            })

      }


      const submitContactFormDetailsHandler = (event)=>{

            event.preventDefault();
            const errorStore = [];
            const copy = {
            }
            
            for(let key in state){
                  const errorCheck = contactUsValidationRules(state[key].value,key);
                  if(!errorCheck.isValid) {
                        errorStore.push(key);
                  }       
                  copy[key] = {...state[key],error:!errorCheck.isValid,errorMessage:errorCheck.validationMessage}
                        
            }


            if(!verify.value){
                  setVerify(prevState=>{
                        return {
                              ...prevState,
                              error:true
                        }
                  })

            }
            
            if(errorStore.length > 0)
            {
                  setState(prevState=>{
                        return {
                              ...prevState,
                              ...copy
                        }
                  })
                  return
            }

            if(errorStore.length === 0 && verify.value)  {

                  const data = {
                        email:state[NameConstants.Email].value,
                        name: state[NameConstants.Name].value,
                        mobile: state[NameConstants.PhoneNumber].value,
                        message: state[NameConstants.Message].value,
                        type:"organizer",
                  }

                  contactWeb(data)
            }
                
      }

      const resetRecaptcha = () => {
            recaptchaInstance.reset();  
      };

      const verifyCallback = ()=>{

            setVerify(prevState=>{
                  return {
                        ...prevState,
                        value:true
                  }
            })
      }

      const expiredCallback = ()=>{
            setVerify(prevState=>{
                  return {
                        ...prevState,
                        value:false
                  }
            })
      }

      return (
                  <div className="col__right">

                        <div className="content content__contact-form">
                              <div className="content__body">
                                    <BackArrow />

                                    <form>
                                          <div className="form-groups-wrapper d-flex flex-column flex-xxl-row flex-xxl-row-3cols">
                                                <div className="form-group mb-xxl-0" style={{marginRight:browserDetect ? "40px":""}}>
                                                      <label htmlFor="eventOrgContactInputName" className="form-label">
                                                            {label[NameConstants.Name]}
                                                      </label>
                                                      <input type="text" className="form-control" id="eventOrgContactInputName"
                                                      name={NameConstants.Name}
                                                      onChange = {handleChange}
                                                      value={state[NameConstants.Name].value}
                                                      onFocus={onfocusHandler}
                                                      placeholder = {placeholders[NameConstants.Name]}
                                                      disabled={isLoading}
                                                      />
                                                       
                                                      {state[NameConstants.Name].error && <label className="form-label fw-normal form-label-error" >{state[NameConstants.Name].errorMessage}</label>}
                                                </div>
                                                {/* <!-- /.form-group --> */}

                                                <div className="form-group mb-xxl-0" style={{marginRight:browserDetect ? "40px":""}}>
                                                      <label htmlFor="eventOrgContactInputEmail" className="form-label">
                                                            {label[NameConstants.Email]}
                                                      </label>
                                                      <input type="email" className="form-control" 
                                                      id="eventOrgContactInputEmail"
                                                      name={NameConstants.Email}
                                                      onChange = {handleChange}
                                                      value={state[NameConstants.Email].value}
                                                      onFocus={onfocusHandler}
                                                      placeholder = {placeholders[NameConstants.Email]}
                                                      disabled={isLoading}
                                                      />
                                                      {state[NameConstants.Email].error && <label className="form-label fw-normal form-label-error" >{state[NameConstants.Email].errorMessage}</label>}
                                                </div>
                                                {/* <!-- /.form-group --> */}

                                                <div className="form-group mb-xxl-0">
                                                      <label htmlFor="eventOrgContactInputPhoneNumber" className="form-label">
                                                            {label[NameConstants.ContactNumber]}
                                                      </label>
                                                      <input type="text" className="form-control" 
                                                      id="eventOrgContactInputPhoneNumber"
                                                      name={NameConstants.PhoneNumber}
                                                      onChange = {handleChange}
                                                      value={state[NameConstants.PhoneNumber].value}
                                                      onFocus={onfocusHandler}
                                                      placeholder = {placeholders[NameConstants.ContactNumber]}
                                                      disabled={isLoading}
                                                      />
                                                      {state[NameConstants.PhoneNumber].error && <label className="form-label fw-normal form-label-error" >{state[NameConstants.PhoneNumber].errorMessage}</label>}
                                                </div>
                                                {/* <!-- /.form-group --> */}
                                          </div>
                                          {/* <!-- /.form-groups-wrapper --> */}

                                          <div className="form-group">
                                                <label htmlFor="eventOrgContactMessage" className="form-label">
                                                      {label[NameConstants.Message]}
                                                </label>
                                                <textarea className="form-control" id="eventOrgContactMessage"
                                                name={NameConstants.Message}
                                                onChange = {handleChange}
                                                value={state[NameConstants.Message].value}
                                                onFocus={onfocusHandler}
                                                placeholder = {placeholders[NameConstants.Message]}
                                                disabled={isLoading}
                                                >
                                                </textarea>
                                                {state[NameConstants.Message].error && <label className="form-label fw-normal form-label-error" >{state[NameConstants.Message].errorMessage}</label>}
                                          </div>

                                          <div className="form-group" onFocus={onfocusHandler}>
                                                <Recaptcha
                                                      sitekey={Config.RECAPTCHA_SITE_KEY}
                                                      render="explicit"
                                                      verifyCallback={verifyCallback}
                                                      // onloadCallback={callback}
                                                      expiredCallback={expiredCallback}
                                                      ref={e => recaptchaInstance = e}
                                                />
                                                {verify.error && 
                                                <label className="form-label fw-normal form-label-error">
                                                      {verify.message}
                                                </label>}
                                          </div>


                                          {/* <!-- /.form-group --> */}
                                          <div className="form-group form-group__cta d-flex flex-column flex-sm-row">
                                                <button type="submit" className="btn btn-xl-lg btn-primary align-self-md-start" onClick={submitContactFormDetailsHandler} disabled={isLoading}>
                                                      {actions.sendMessage}
                                                </button>
                                          </div>
                                          {/* <!-- /.form-group --> */}
                                    </form>
                                   

                              </div>
                              {/* <!-- .content__body --> */}
                        </div>
                        {/* <!-- .content --> */}

                  </div>
      )
}

export default OrganizerSimpleForm
