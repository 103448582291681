export const Config = {
      //API_URL: 'https://stage.centraltickets.co/',
      API_URL: 'https://api.centraltickets.co.uk/',
     RECAPTCHA_SITE_KEY : process.env.REACT_APP_RECAPTCHA_SITE_KEY,
     DOMAIN_CONSTANT_AJTIX : "Ajtix",
     DOMAIN_CONSTANT_TFM : "ticketsformedics",
     AJTIX_URL_CHECK : "ajtix",
     TFM_URL_CHECK : "ticketsformedics",
     
}
 