import React, { useEffect, useLayoutEffect, useState } from 'react'

// external css
// redux and react hooks
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

// child component
import MyWallet from './../index';
import Loader from '../../../Ui/BookingModuleLoader';

// packages
// Actions & RTK query or mutations
import { useLazyGetSessionKeyDetailsQuery } from '../../../Services/modules/BookingModule';
import { setSpinner, setAlert, setHideWalletTopUpCopy } from '../../../Store/UI';
import { setWebViewSession, changeToken, changeCtAcessRefreshToken, setWalletBalance, setTomMember, setDarkMode } from '../../../Store/User';
import { setWalletParams } from '../../../Store/Wallet';

// Constants
import { errors, messages } from '../../../resources/en';

const WalletTopUpWeb = () => {

      const [paySuccess,setPaySuccess]    =     useState(false)
      const [loader,setLoader]            =     useState(true)
      const { token }                     =     useParams()
      const dispatch                      =     useDispatch()
      const navigate                      =     useNavigate()
      const user                          =     useSelector(state=>state.user)
      const { state, pathname, search }   =     useLocation();
      const [getSessionKeyDetails, { data, isSuccess, isLoading, isFetching, error }]  =    useLazyGetSessionKeyDetailsQuery();

      let topUp

      if(state){
            topUp = state.topUp
      }

      useLayoutEffect(()=>{

            if(!pathname.includes("payment-success") && !pathname.includes("payment-failure")){
                  dispatch(changeToken({ct_access_token : ""})) 
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

      useEffect(()=>{   

            const searchParams = new URLSearchParams(search);  
            const darkMode = searchParams.get('darkMode')?.toString()
            if(darkMode === "true") {
                  document.body.classList.add('dark-mode');
                  dispatch(setDarkMode({ct_app_dark_mode : true}))
            } else {
                  document.body.classList.remove('dark-mode');
                  dispatch(setDarkMode({ct_app_dark_mode : false}))
            }

            if(state) {
                 if(state.topUp) {
                        dispatch(setSpinner({status : "request"}));
                        getSessionKeyDetails({ session_id : token })
                  } 
            } else {
                  
                  if(pathname.includes("payment-success") || pathname.includes("payment-failure")){

                        const searchParams = new URLSearchParams(search);
                        const client = searchParams.get('client');
                        const bookingStatus = pathname.includes("payment-success") ? "success" : "failure";
                        const message = bookingStatus === "success" ?  messages.SuccessAddBalance : messages.FailureAddBalance;
                        if(client === "app"){
                              if(window.ReactNativeWebView){
                                    window.ReactNativeWebView.postMessage(JSON.stringify({
                                          "stay": true,
                                          "redirect": false,
                                          "redirectTo": "",
                                          "status": bookingStatus === "success" ? 1 : 0,
                                          "message": message
                                    }));
                              } 
                              else {
                                    if(bookingStatus === "success"){
                                          dispatch(setWalletParams({isTopUpOpen : true,step : 'step3',paymentMethod : "",topUpAmount : 0}))
                                          dispatch(setHideWalletTopUpCopy({hideWalletTopUpCopy : true}))
                                          setPaySuccess(true)
                                    } 
                                    else {

                                          dispatch(setAlert({model:true,title:bookingStatus === "success" ? messages.modalSuccessTitle :messages.modalAlertTitle,message: message}))
                                    }
                              }
                        }
                        navigate(`/wallet-topup/${user.ct_web_view_session}`, { replace : true });
                        setLoader(false)
                  } else {
                        dispatch(setSpinner({status : "request"}));
                        getSessionKeyDetails({ session_id : token })
                  }
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[getSessionKeyDetails, token, dispatch])

      useEffect(()=>{
            if(isSuccess){
                  // Call get User Wallet Balance API  to update wallet balance
                  if(data.status === "1"){
                        if(data.data.is_set_cookie === "0" || data.data.is_set_cookie === "1"){
                              if(data.data.is_set_cookie === "0") {
                                    dispatch(setWebViewSession({ct_web_view_session : token }))
                              }
                              if(data.data.is_set_cookie === "1" && !user.ct_web_view_session) {
                                    return;
                              }
                              dispatch(setTomMember({ct_is_top_member : data.data.is_top_member || 0})) 
                              // dispatch(changeToken({ct_access_token : data.data.token})) 
                              dispatch(changeCtAcessRefreshToken({ ct_access_token : data.data.token, ct_refresh_token : data.data.refresh_token}))
                              dispatch(setSpinner({status : ""}));
                              setLoader(false)
                              dispatch(setWalletBalance({ct_wallet_balance : parseFloat(data.data.wallet_balance)}))
                        }
                  }
                  else if(data.status === "0"){
                        // dispatch(setSpinner({status : ""}));
                        dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:data.errors || data.error || data.message || errors.NotFound404}));
                  }
            }

            if(error){
                  dispatch(setAlert({model:true,title:messages.modalAlertTitle,message:error.error || error.data.error || error.data.message || errors.NotFound404 }))
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data, isSuccess, isLoading, isFetching, error, dispatch])
    
      return (
            <div data-scroll-container id="WebViewWalletModule">
                  <div className='section__web-view-save-card' style={{"display" :loader ? "" :"none"}}>
                        <Loader show={loader}/>
                  </div>
                  {!loader && <MyWallet webView={true} topUp={topUp} paySuccess={paySuccess}/> }
            </div>
      )
}

export default WalletTopUpWeb;
