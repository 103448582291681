export const URL = {
      SITE_BASE_URL :"https://centraltickets.co.uk/",      
      APPLE_STORE: 'https://apps.apple.com/in/app/central-tickets-member-app/id1599267466',
      GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=centraltickets.co.uk',
      Image_Base_Url : 'https://centralticket.nyc3.cdn.digitaloceanspaces.com/Rebranding_assets%2F',
      Download_Booking_Confirmation_URL : 'https://admin.centraltickets.co.uk/download_booking_confirmation/',
      Download_Booking_TTG_Confirmation_URL : 'https://admin.centraltickets.co.uk/encore-booking-confirmation-download?bookingRef=',
      Get_Uptake_Feedback_report_URL : 'https://client.centraltickets.co.uk/get_feedback_report/',
      Uptake_Export_Button_URL : 'https://client.centraltickets.co.uk/provisional_guest_list/',
      Trust_Pilot_URL : 'https://www.trustpilot.com/evaluate/centraltickets.co.uk',
      Trust_Pilot_Centraltickets_Page :"https://www.trustpilot.com/review/centraltickets.co.uk"
      
}
